import React from 'react'

function TasksPage() {
    return (
        <div>
            <div style={{ minHeight: '100vh' }}>
                Tasks Page
            </div>
        </div>
    )
}

export default TasksPage
