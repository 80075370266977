import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react'

function VfDatePicker({
    showLabel, required, optional, isDisable, label, color, placeholder, onChange, ref, value
}) {
    const style = {
        label: {
            color: color === "secondary" ? "#fff" : 'var(--primary)',
            fontWeight: 700,
            opacity: 0.7
        }
    };
    return (
        <div className="my-2">
            {
                showLabel &&
                <div style={style.label} className="my-1 ms-2">
                    <div className="d-inline-block pg-text__bold ">{label}</div>
                    {required && <span className="textc-primary fst-italic"> (Required)</span>}
                    {optional && !isDisable && <span className="textc-primary fst-italic"> (Optional)</span>}
                </div>
            }
            <DatePicker
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                ref={ref}
            />
        </div>
    )
}

export default VfDatePicker
