import React from 'react'
import AdminCardSection1 from './sections/AdminCardSection1';
import ChartSection1 from './sections/ChartSection1';
import ChartSection2 from './sections/ChartSection2';

function SampleDashboardPage() {
    return (
        <div>
            <>
                <AdminCardSection1 />
                <ChartSection1 />
                <ChartSection2 />
            </>
        </div>
    )
}

export default SampleDashboardPage
