import { Avatar, Switch } from 'antd';
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import { AntDesignOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import toastr from 'react-hot-toast'

import { updateClientStatus } from '../../../store/actions/ClientAndProspects'
import { getMultipleCases } from '../../../store/actions/case';
import CaseViewInViewClientPage from './CaseViewInViewClientPage';

function ViewClientPage({ data }) {
    const l = useLocation();
    const h = useHistory();
    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token);
    const updateClientFn = (data) => dispatch(updateClientStatus(data, token))
    const [userData, setuserData] = useState(l.state)
    useEffect(() => {
        console.log('data', l)
    }, [])

    const [changingCaseType, setchangingCaseType] = useState(false)
    const transitProspectToCase = async (e) => {
        console.log('log', e)
        setchangingCaseType(true)
        const response = await updateClientFn({
            clientType: e ? 'client' : 'prospects',
            email: userData.email,
        });
        setchangingCaseType(false)
        if (!response.error) {
            setuserData(ud => {
                let _ud = { ...ud };
                _ud.clientType = e ? 'client' : 'prospects';
                return _ud;
            })
            toastr.success("Client type changed");
        }
    }

    return (
        <div>
            <div className="my-2 mb-4 fw-bold text-primary cp" onClick={() => h.go(-1)}>
                <ion-icon name="arrow-back-outline"></ion-icon> <span style={{ verticalAlign: 'text-bottom' }}>Client And Prospects</span>
            </div>
            <h3>Client Detail Page</h3>
            <div className="mx-3 card shadow-sm">
                <div className="row my-5">
                    <div className="col-5 col-md-5 col-lg-3">
                        <div className="my-3 text-center">
                            <Avatar
                                src="https://joeschmoe.io/api/v1/jon"
                                size={200}
                            // icon={<AntDesignOutlined />}
                            />,
                        </div>
                    </div>
                    <div className="col-5 col-md-5 col-lg-3 my-auto">
                        <h5><b>Name :</b> {userData.fName} {userData.lName}</h5>
                        <h5><b>Status :</b> {userData.status}</h5>
                        <h5 className="text-capitalize"><b>Case Type :</b> {userData.clientType}</h5>
                        <h5><b>Created At :</b> {moment(userData.createdAt).fromNow()}</h5>
                        <h5><b>Updated At :</b> {moment(userData.updatedAt).fromNow()}</h5>
                    </div>
                    <div className="col text-end px-4">
                        <Switch
                            loading={changingCaseType}
                            checked={userData.clientType === "client"}
                            unCheckedChildren="Prospect" checkedChildren="Client" onChange={transitProspectToCase} />
                    </div>
                </div>
            </div>
            {/* client details */}
            <div className="container-xxl">
                <div className="row justify-content-start">
                    <div className="col-11 col-md-6 col-lg-3 my-3">
                        <div className="card shadow-sm p-3 br">
                            <h4>Personal Detail:</h4>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    First  Name :
                                </div>
                                {userData.fName}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Last Name :
                                </div>
                                {userData.lName}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    DOB :
                                </div> {userData.dob}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Maritial Status:
                                </div>
                                {userData.maritialStatus}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Country of Residence:
                                </div>
                                {userData.cofr}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Country of Citizenship:
                                </div>
                                {userData.cofc}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Date of birth:
                                </div>
                                {userData.dob}
                            </div>
                        </div>
                    </div>
                    <div className="col-11 col-md-6 col-lg-3 my-3">
                        <div className="card shadow-sm p-3 br">
                            <h4>Contact Detail:</h4>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Primary Email:
                                </div>
                                {userData.email}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Secondary Email:
                                </div>
                                {userData.semail ? userData.semail : '--'}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Primary phone:
                                </div>
                                {userData.phone}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Secondary phone:
                                </div>
                                {userData.sphone ? userData.sphone : '--'}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Contact Address:
                                </div>
                                {userData.address}
                            </div>
                        </div>
                    </div>
                    <div className="col-11 col-md-6 col-lg-3 my-3">
                        <div className="card shadow-sm p-3 br">
                            <h4>Family Detail:</h4>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    First Name:
                                </div>
                                {userData.ffName ? userData.ffName : '--'}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Last Name:
                                </div>
                                {userData.flName ? userData.flName : '--'}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Email:
                                </div>
                                {userData.femail ? userData.femail : '--'}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Phone:
                                </div>
                                {userData.fphone ? userData.fphone : '--'}
                            </div>

                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Country of Residence:
                                </div>
                                {userData.fcofr ? userData.fcofr : '--'}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Country of Citizenship:
                                </div>
                                {userData.fcofc ? userData.fcofc : '--'}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Has Children:
                                </div>
                                {userData.hasChildren ? "Yes" : 'No'}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Number of Children:
                                </div>
                                {userData.nofc ? userData.nofc : '--'}
                            </div>
                            <div className="ms-2 mt-2">
                                <div className="fw-bold">
                                    Children Detail:
                                </div>
                                {userData.cdetails ? userData.cdetails : '--'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* client details end */}
            {/* client case details */}
            <h4>Case Information</h4>
            <CaseViewInViewClientPage ids={userData.cases} />
            {/* client case details end*/}


        </div>
    )
}

export default ViewClientPage

