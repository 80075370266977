import React from 'react';
import { Route, Redirect, useLocation } from "react-router-dom";

export const HomepageRoute = ({ component: Component,
    layout: Layout,
    isAuth: isAuthenticated,
    userType,
    ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (<Layout>
                    <Component {...props} />
                </Layout>)
            }}
        />
    )
}

