import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Radio } from 'antd';
import { getApplicant } from '../../../store/actions/singlecase';
import AddApplicantModel from '../../../component/Practitioner/Case/AddApplicantModel';

const { TabPane } = Tabs;


function CaseQuestionnairePage({ caseId, activeTab }) {

    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token);
    const getApplicantAction = (id) => dispatch(getApplicant(id, token))
    const [applicants, setApplicants] = useState([]);
    const [showAddNewApplicant, setshowAddNewApplicant] = useState(false)
    const [currentApplicant, setcurrentApplicant] = useState(null)

    useEffect(() => {
        if (activeTab === "2") __getApplicant(caseId)
    }, [activeTab])

    useEffect(() => {
        console.log('applicant data updated', applicants)
    }, [applicants])

    const __getApplicant = async (id) => {
        const response = await getApplicantAction(id)
        console.log(response.payload)
        if (!response.error) setApplicants(response.payload.data);
    }
    return (
        <div>
            <div className="d-flex justify-content-end">
                {/* <h5>Case Questionnaire page</h5> */}
                <button className="btn btn-primary br" onClick={() => setshowAddNewApplicant(true)}> + Add Applicant</button>
                {showAddNewApplicant && <AddApplicantModel cid={applicants[0]?.client} caseId={caseId} show={showAddNewApplicant} onCancel={() => setshowAddNewApplicant(false)} />}
            </div>
            <div className="my-2">
                <Tabs tabPosition="left" type="card">
                    {applicants.map((_applicant, i) => {
                        return <TabPane
                            tab={<div>
                                <div>
                                    <b>{_applicant.name}</b>
                                </div>
                                <div className="badge bg-secondary">{_applicant.relationship}</div>
                            </div>}
                            key={i + 1}
                            style={{ background: 'var(--secondary)', height: '100vh' }}
                        >
                            <div className="text-center my-3">
                                <h1>{i}</h1>
                                {_applicant.questionnaireAssigned
                                    ? "Questionnaire Assignment Coming soon.."
                                    : `Questionnaire Not Assigned for ${_applicant.name}`}
                            </div>
                        </TabPane>

                    })}
                </Tabs>
            </div>
        </div>
    )
}

export default CaseQuestionnairePage
