import { createAction } from "redux-api-middleware";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_API;

export const CASE_CREATE_REQUEST = "CASE_CREATE_REQUEST";
export const CASE_CREATE_SUCCESS = "CASE_CREATE_SUCCESS";
export const CASE_CREATE_FAILURE = "CASE_CREATE_FAILURE";

export const createCase = (data, token) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/case`,
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
        types: [
            CASE_CREATE_REQUEST,
            CASE_CREATE_SUCCESS,
            CASE_CREATE_FAILURE,
        ],
    });
};


export const getCases = (url, token) => {
    return axios.get(`${BASE_URL}/api/v1/case${url}`, { headers: { Authorization: `Bearer ${token}` } });
}

export const getCasesForCustomer = (token) => {
    return axios.get(`${BASE_URL}/api/v1/case/customer`, { headers: { Authorization: `Bearer ${token}` } });
}

export const getMultipleCases = (data, token) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/case/multiple`,
        method: "POST",
        body: JSON.stringify({ ids: data }),
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
        types: [
            CASE_CREATE_REQUEST,
            CASE_CREATE_SUCCESS,
            CASE_CREATE_FAILURE,
        ],
    });
};

export const getSingleCase = (id, token) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/case/${id}`,
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
        types: [
            CASE_CREATE_REQUEST,
            CASE_CREATE_SUCCESS,
            CASE_CREATE_FAILURE,
        ],
    });
};

export const deleteSingleCase = (id, token) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/case/${id}`,
        method: "DELETE",
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
        types: [
            CASE_CREATE_REQUEST,
            CASE_CREATE_SUCCESS,
            CASE_CREATE_FAILURE,
        ],
    });
};

export const updateSingleCase = (id, token, data) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/case/${id}`,
        method: "PUT",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
        types: [
            CASE_CREATE_REQUEST,
            CASE_CREATE_SUCCESS,
            CASE_CREATE_FAILURE,
        ],
    });
};
