import React from 'react'
import { Modal, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
function VfPrompt({ onYes, onNo, show, text, loading = false }) {
    return (
        <Modal
            visible={show}
            onCancel={onNo}
            footer={[
                <button className="btn btn-secondary px-5" onClick={onNo}>No</button>,
                <button className="btn btn-primary px-5 mx-1" onClick={onYes}>{loading ? <Spin className="text-light px-1" indicator={<LoadingOutlined />} /> : <span className="text-light">Yes</span> }</button>
            ]}
        >
            <h4>
                <b>{text}</b>
            </h4>
        </Modal>
    )
}

export default VfPrompt
