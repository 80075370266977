import { Button } from 'antd';
import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import AddNewClient from '../../component/Practitioner/Client/AddNewClient';
import VfInput from '../../component/utils/VfInput'
import VfSearchInput from '../../component/utils/VfSearchInput';
import { getClients } from '../../store/actions/ClientAndProspects';
import ClientCard from '../../component/Practitioner/Client/ClientCard';
import VfSelect from '../../component/utils/VfSelect';
import L_ClientPage from '../../component/Loading/Case/L_ClientPage';

function ClientAndProspectPage() {
    const h = useHistory();
    const [filter, setFilter] = useState("all");
    const [showAddClientModal, setshowAddClientModal] = useState(false)
    const token = useSelector(state => state.auth.token);
    const [url, seturl] = useState("?filter=all");
    const [searchText, setsearchText] = useState("");
    const { isLoading, data, refetch } = useQuery('clientList', () => getClients(url.toLowerCase(), token));

    const onSearch = (e) => {
        e.preventDefault()
        console.log(searchText)
        seturl(`?filter=${filter}&search=${searchText}`)
    }

    useEffect(() => {
        if (searchText === "") {
            seturl(`?filter=${filter}&search=${searchText}`)
        }
    }, [searchText])

    useEffect(() => {
        refetch()
    }, [url])

    return (
        <div className="">
            <div style={{ minHeight: '100vh' }}>
                <div className="container-xxl">

                    <div className="row justify-content-between my-3">
                        <div className="col-12 col-md-3 text-start mt-2 text-secondary">
                            <b>Filter</b>
                            <VfSelect
                                placeholder="All"
                                value={filter}
                                onChange={(e) => { setFilter(e); seturl('?filter=' + e); }}
                                selectOptions={['All', 'Client', 'Prospects']} />
                        </div>
                        <div className="col-12 col-md-6">
                            <form onSubmit={onSearch}>
                                <div className="d-flex">
                                    <VfSearchInput
                                        showLabel
                                        inputHandler={(e) => setsearchText(e.target.value)}
                                        label="Search"
                                        icon={<ion-icon name="search-outline"></ion-icon>}
                                        className="w-100"
                                        onSearch={onSearch}
                                        placeholder="Search name,email,name,phone,Case number"
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-md-3 text-end mt-4">
                            <button className="btn btn-primary br-lg px-3 fw-bold w-100 mt-3" onClick={() => h.push('/practitioner/client_and_prospects/add_cases')}>
                                <ion-icon name="person-add-outline"></ion-icon>
                                <span className="ms-2">Add Client</span>
                            </button>
                        </div>
                    </div>
                    {
                        isLoading
                            ? <div className="text-center">
                                <L_ClientPage />
                            </div>
                            : data?.data?.data.length > 0
                                ? <div className="row gy-2 gx-md-3 justify-content-center justify-content-md-start mt-4">
                                    {data?.data?.data.map((client, i) => {
                                        return <div className="col-12 col-md-5 col-lg-4 col-xl-3" key={i}>
                                            <ClientCard
                                                data={client}
                                                index={i}
                                                refereshPage={refetch}
                                            />
                                        </div>
                                    })}
                                    {/* <div className="text-center">
                                        <button className="btn btn-primary"> Load More ...</button>
                                    </div> */}
                                </div> : <div className="text-center text-warning">No Cases</div>}

                </div>
            </div >
        </div >
    )
}

export default ClientAndProspectPage
