import ContentLoader from 'react-content-loader'

function L_EditCase() {
    return (
        <ContentLoader viewBox="0 0 480 350">
            {/* Only SVG shapes */}
            <rect x="0" y="0" rx="5" ry="5" width="120" height="25" />

            <rect x="0" y="40" rx="4" ry="4" width="200" height="10" />
            <rect x="220" y="40" rx="4" ry="4" width="200" height="10" />

            <rect x="0" y="60" rx="3" ry="3" width="200" height="25" />
            <rect x="0" y="100" rx="3" ry="3" width="200" height="25" />
            <rect x="0" y="140" rx="3" ry="3" width="200" height="25" />
            <rect x="0" y="180" rx="3" ry="3" width="200" height="25" />
            <rect x="0" y="220" rx="3" ry="3" width="200" height="25" />
            <rect x="0" y="260" rx="3" ry="3" width="200" height="25" />

            <rect x="220" y="60" rx="3" ry="3" width="200" height="25" />
            <rect x="220" y="100" rx="3" ry="3" width="200" height="25" />
            <rect x="220" y="140" rx="3" ry="3" width="200" height="25" />
            <rect x="220" y="180" rx="3" ry="3" width="200" height="25" />
            <rect x="220" y="220" rx="3" ry="3" width="200" height="25" />
            <rect x="220" y="260" rx="3" ry="3" width="200" height="25" />
            
            {/* button */}
            <rect x="200" y="320" rx="3" ry="3" width="100" height="25" />
            <rect x="320" y="320" rx="3" ry="3" width="100" height="25" />
        </ContentLoader>
    )
}

export default L_EditCase
