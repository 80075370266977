import React from 'react'
import ContentLoader from 'react-content-loader'

function L_CasePage() {
    return (
        <ContentLoader viewBox="0 0 480 350">
            {/* Only SVG shapes */}
            <rect x="0" y="10" rx="4" ry="4" width="470" height="20" />

            <rect x="0" y="40" rx="3" ry="3" width="470" height="25" />
            <rect x="0" y="70" rx="3" ry="3" width="470" height="25" />
            <rect x="0" y="100" rx="3" ry="3" width="470" height="25" />
            <rect x="0" y="130" rx="3" ry="3" width="470" height="25" />
            <rect x="0" y="160" rx="3" ry="3" width="470" height="25" />
            <rect x="0" y="190" rx="3" ry="3" width="470" height="25" />
            <rect x="0" y="220" rx="3" ry="3" width="470" height="25" />
            <rect x="0" y="250" rx="3" ry="3" width="470" height="25" />
            <rect x="0" y="280" rx="3" ry="3" width="470" height="25" />
        </ContentLoader>
    )

}

export default L_CasePage
