import React, { useState, useEffect } from "react";
import { Calendar, Radio } from "antd";
import { Modal, Button, Form } from "react-bootstrap";
import "./calendar.css";

const Popup = (props) => {
  const [min, setMin] = useState("");
  const [date, setDate] = useState("");
  const [timezone, setTimeZone] = useState("");
  const [getTime, setGetTime] = useState("");
  const [scheduleTime, setScheduleTime] = useState("");
  const [scheduleConfirm, setScheduleConfirm] = useState(false);

  useEffect(() => {
    let time = calcTime(timezone);
    setGetTime(time);
  }, [timezone]);

  let scheduleTiming = [
    "09:00 AM",
    "09:15 AM",
    "09:30 AM",
    "09:45 AM",
    "10:00 AM",
    "10:15 AM",
    "10:30 AM",
    "10:45 AM",
    "11:00 AM",
    "11:15 AM",
    "11:30 AM",
    "11:45 AM",
    "12:00 AM",
    "12:15 PM",
    "12:30 PM",
    "12:45 PM",
    "01:00 PM",
    "01:15 PM",
    "01:30 PM",
    "01:45 PM",
    "02:00 PM",
    "02:15 PM",
    "02:30 PM",
    "02:45 PM",
    "03:00 PM",
    "03:15 PM",
    "03:30 PM",
    "03:45 PM",
    "04:00 PM",
    "04:15 PM",
    "04:30 PM",
    "04:45 PM",
    "05:00 PM",
    "05:15 PM",
    "05:30 PM",
    "05:45 PM",
    "06:00 PM",
    "06:15 PM",
  ];

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header> */}
      {/* <Modal.Title id="contained-modal-title-vcenter"></Modal.Title> */}
      {/* </Modal.Header> */}
      {!scheduleConfirm ? (
        <Modal.Body>
          <div className="row">
            <div className={`${min !== "" && date !== "" ? "col-5" : "col-6"}`}>
              <h4 className="text-center">Select Meeting Minute</h4>
              <div className="row justify-content-evenly  text-center ">
                <div
                  className="col-5 btn btn-primary btn-lg mt-3  "
                  onClick={(e) => setMin(15)}
                >
                  15 Min
                </div>
                <div
                  className="col-5 btn btn-success btn-lg mt-3  "
                  onClick={(e) => setMin(30)}
                >
                  30 Min
                </div>
                <div
                  className="col-5 btn btn-danger btn-lg mt-3  "
                  onClick={(e) => setMin(45)}
                >
                  45 Min
                </div>
                <div
                  className="col-5 btn btn-warning btn-lg mt-3  "
                  onClick={(e) => setMin(60)}
                >
                  60 Min
                </div>
              </div>

              <br />
              {/* <div className="mx-3">
              {min && <h4>{min} Minute Meeting</h4>}
              <h4>{date}</h4>
              <h4>{timezone}</h4>
              <h4>{getTime}</h4>
            </div> */}
            </div>
            <div
              className={`${
                min !== "" && date !== "" ? "col-4" : "col-6"
              } my-3`}
            >
              <div className="site-calendar-demo-card">
                <h6>Select a Date & Time</h6>
                <h4>Select a Day</h4>
                <Calendar
                  fullscreen={false}
                  onChange={(date) => setDate(convert(date._d))}
                />
                {/* <select name="timezone_offset" id="timezone-offset" class="span5"> */}
                <Form.Select
                  aria-label="time-zone"
                  onClick={(e) => setTimeZone(e.target.value)}
                >
                  <option value="-12.00">
                    Eniwetok, Kwajalein {calcTime(-12.0)}
                  </option>
                  <option value="-11.00">
                    Midway Island, Samoa {calcTime(-11.0)}
                  </option>
                  <option value="-10.00">Hawaii {calcTime(-10.0)}</option>
                  <option value="-9.5">Taiohae {calcTime(-9.5)}</option>
                  <option value="-9.0">Alaska {calcTime(-9.0)}</option>
                  <option value="-8.0">
                    Pacific Time (US &amp; Canada) {calcTime(-8.0)}
                  </option>
                  <option value="-7.0">
                    Mountain Time (US &amp; Canada) {calcTime(-7.0)}
                  </option>
                  <option value="-6.0">
                    Central Time (US &amp; Canada), Mexico City {calcTime(-6.0)}
                  </option>
                  <option value="-5.0">
                    Eastern Time (US &amp; Canada), Bogota, Lima{" "}
                    {calcTime(-5.0)}
                  </option>
                  <option value="-4.5">Caracas {calcTime(-4.5)}</option>
                  <option value="-4.0">
                    Atlantic Time (Canada), Caracas, La Paz {calcTime(-4.0)}
                  </option>
                  <option value="-3.5">Newfoundland {calcTime(-3.5)}</option>
                  <option value="-3.0">
                    Brazil, Buenos Aires, Georgetown {calcTime(-3.0)}
                  </option>
                  <option value="-2.0">Mid-Atlantic {calcTime(-2.0)}</option>
                  <option value="-1.0">
                    Azores, Cape Verde Islands {calcTime(-1.0)}
                  </option>
                  <option value="+0.0" selected="selected">
                    Western Europe Time, London, Lisbon, Casablanca{" "}
                    {calcTime(+0.0)}
                  </option>
                  <option value="+1.0">
                    Brussels, Copenhagen, Madrid, Paris {calcTime(+1.0)}
                  </option>
                  <option value="+2.0">
                    Kaliningrad, South Africa {calcTime(+2.0)}
                  </option>
                  <option value="+3.0">
                    Baghdad, Riyadh, Moscow, St. Petersburg {calcTime(+3.0)}
                  </option>
                  <option value="+3.5">{calcTime(+3.5)}Tehran </option>
                  <option value="+4.0">
                    Abu Dhabi, Muscat, Baku, Tbilisi {calcTime(+4.0)}
                  </option>
                  <option value="+4.5">Kabul {calcTime(+4.5)}</option>
                  <option value="+5.0">
                    Ekaterinburg, Islamabad, Karachi, Tashkent {calcTime(+5.0)}
                  </option>
                  <option value="+5.5">
                    Bombay, Calcutta, Madras, New Delhi {calcTime(+5.5)}
                  </option>
                  <option value="+5.75">
                    Kathmandu, Pokhara {calcTime(+5.75)}
                  </option>
                  <option value="+6.0">
                    Almaty, Dhaka, Colombo {calcTime(+6.0)}
                  </option>
                  <option value="+6.5">
                    Yangon, Mandalay {calcTime(+6.5)}
                  </option>
                  <option value="+7.0">
                    Bangkok, Hanoi, Jakarta {calcTime(+7.0)}
                  </option>
                  <option value="+8.0">
                    Beijing, Perth, Singapore, Hong Kong {calcTime(+8.0)}
                  </option>
                  <option value="+8.75">Eucla {calcTime(+8.75)}</option>
                  <option value="+9.0">
                    Tokyo, Seoul, Osaka, Sapporo, Yakutsk {calcTime(+9.0)}
                  </option>
                  <option value="+9.5">
                    Adelaide, Darwin {calcTime(+9.5)}
                  </option>
                  <option value="+10.0">
                    Eastern Australia, Guam, Vladivostok {calcTime(+10.0)}
                  </option>
                  <option value="+10.5">
                    Lord Howe Island {calcTime(+10.5)}
                  </option>
                  <option value="+11.0">
                    Magadan, Solomon Islands, New Caledonia {calcTime(+11.0)}
                  </option>
                  <option value="+11.5">
                    Norfolk Island {calcTime(+11.5)}
                  </option>
                  <option value="+12.0">
                    Auckland, Wellington, Fiji, Kamchatka {calcTime(+12.0)}
                  </option>
                  <option value="+12.75">
                    Chatham Islands {calcTime(+12.75)}
                  </option>
                  <option value="+13.0">
                    Apia, Nukualofa {calcTime(+13.0)}
                  </option>
                  <option value="+14.0">
                    Line Islands, Tokelau {calcTime(+14.0)}
                  </option>
                  {/* </select> */}
                </Form.Select>
              </div>
            </div>
            {min !== "" && date !== "" && (
              <div className="col-3">
                <ul className="schedule_time row">
                  {scheduleTiming.map((time, i) => (
                    <div
                      className={` ${
                        scheduleTime === time
                          ? "btn-outline"
                          : "row btn btn-primary mt-3 col-11"
                      } `}
                      key={i}
                      onClick={(e) => setScheduleTime(e.target.textContent)}
                    >
                      {scheduleTime !== time ? (
                        time
                      ) : (
                        <div className="mt-3 schedule_btn  row justify-content-evenly">
                          <button className="col-5 btn btn-primary ">
                            {time}
                          </button>
                          <button
                            className="col-6 btn btn-primary "
                            onClick={() => setScheduleConfirm(true)}
                          >
                            confirm
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <button
            className="btn btn-primary"
            onClick={() => setScheduleConfirm(false)}
          >
            Back
          </button>
          <div className="row mt-3">
            <div className="col-6">
              <h3>{min} Minute Meeting</h3>
            </div>
            <div className="col-6">
              <h4>Enter Details</h4>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="name" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="name@example.com" />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    Please share anything that will help prepare for our
                    meeting.
                  </Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>
                <Button>Schedule Event</Button>
              </Form>
            </div>
          </div>
        </Modal.Body>
      )}
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  function calcTime(offset) {
    let d = new Date();
    let utc = d.getTime() + d.getTimezoneOffset() * 60000;
    let nd = new Date(utc + 3600000 * offset);
    let time = nd.toLocaleString().slice(12);
    // return time as a string
    return time;
  }
};

export default Popup;
