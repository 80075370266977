import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Menu, Dropdown, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/actions/auth';

const PracHeader = () => {
    const dispatch = useDispatch();
    const [collapse, setcollapse] = useState(false)
    const [dropDownOpen, setdropDownOpen] = useState(false)
    const currentPage = useSelector(state => state.ui.currentPractPage)
    const onClick = () => {
        setcollapse(!collapse)
    }

    const toggle = () => {
        setdropDownOpen(!dropDownOpen)
    }

    const __logout = async () => {
        const response = await dispatch(logout())
    }

    return (
        <div className="position-fixed bg-white  flexible-navbar py-2 d-flex justify-content-between" scrolling style={{ top: 0, zIndex: 99, borderBottom: '2px solid rgba(0,0,0,0.2)', width: "calc(100% - 250px)" }}>
            <div className="fw-bold ms-2 my-auto">
                <div className="text-dark">
                    {currentPage}
                </div>
            </div>
            <div className="me-5">
                <Dropdown overlay={<MenuComponent __logout={__logout} />} placement="bottomRight" arrow trigger={['click']}>
                    <div>
                        <i class="far fa-user-circle text-primary fs-2 me-2"></i>
                    </div>
                </Dropdown>
            </div>
        </div>
    );
}

export default PracHeader;

const MenuComponent = ({ __logout }) => {

    return (
        <Menu>
            <Menu.Item>
                <div className="fw-bold my-auto px-2" onClick={__logout}>
                    <i class="far fa-user-circle text-primary fs-2 me-2"></i> <span style={{verticalAlign:'text-bottom',lineHeight:'40px'}}>Logout</span>
                </div>
            </Menu.Item>
        </Menu>
    );
}