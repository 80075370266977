import React, { useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import toast from 'react-hot-toast';
import _ from 'lodash';
import LaptopImage2 from '../../assets/static/macbook_2.png'
import PricingComponent from '../../component/static/PricingComponent';

import { signupForm } from '../../store/actions/auth';
import VfInput from '../../component/utils/VfInput';

export const Homepage = () => {
    const dispatch = useDispatch()
    const h = useHistory();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    const [formSubmitting, setFormSubmitting] = useState(false)
    const submitSignUpForm = async () => {
        setFormSubmitting(true)
        console.log(email, company, name)
        const response = await dispatch(signupForm(name, email, company));
        if (!response.error) {
            console.log(response.payload)
            toast.success('Form submitted successfully!!')
        }
        setFormSubmitting(false);
    }
    // const history = useHistory();
    return (
        <div className="" >
            <div>
                <div className="container" >
                    <div className="row py-5 justify-content-between" style={{ height: '100vh' }}>
                        <div className="col-5 my-auto" data-aos="fade-right">
                            <div className="text-primary fw-bold mt-5" style={{ fontSize: 56, lineHeight: "52px" }}>
                                All-in-one CRM suite for Immigration Practitioners
                            </div>
                            <div className="fw-bold my-4">
                                Our case management software helps Canadian Immigration Practitioners automate and grow their practice. Let us show you how.
                            </div>
                            <div className="d-flex justify-content-start">
                                <div className="me-3">
                                    <i className="fas fa-check" /> All-in-one suite
                                </div>
                                <div className="me-3">
                                    <i className="fas fa-check" /> Easy setup
                                </div>
                                <div className="me-3">
                                    <i className="fas fa-check" /> Live chat support
                                </div>
                            </div>
                            <div className="d-flex justify-content-start my-3">
                                <div className="me-2">
                                    <button className="btn btn-primary br" onClick={() => h.push('/static/login')}>Get Started</button>
                                </div>
                                <div className="me-2" >
                                    <button className="btn btn-outline btn-default br" onClick={() =>
                                        document.getElementById('pricing').scrollIntoView()}>Pricing plan</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 my-auto" style={{ zIndex: 0 }} >
                            <img className="" src={LaptopImage2} style={{ width: 800, }} data-aos="fade-up"
                                data-aos-duration="3000" />
                        </div>
                    </div>
                </div>
            </div>

            {/* section 1.1 */}

            <div className="container" style={{ height: '100vh' }}>
                <div className="row h-100">
                    <div className="col-4 text-center my-auto" data-aos="fade-up">
                        <i className="fas fa-user-clock fs-1 my-3 text-primary" />
                        <div className="fw-bold text-primary fs-2">
                            Automate more and do less
                        </div>
                        Automate more and spend less time on repeatable tasks by using our productivity tools.
                    </div>
                    <div className="col-4 text-center  my-auto" data-aos="fade-up">
                        <i className="fas fa-laptop fs-1 my-3 text-primary" />
                        <div className="fw-bold text-primary fs-2 mb-3">
                            Work remotely
                        </div>
                        Work anywhere using our secure cloud-based platform.
                    </div>
                    <div className="col-4 text-center  my-auto" data-aos="fade-up">
                        <i className="fas fa-shield-alt fs-1 my-3 text-primary" />
                        <div className="fw-bold text-primary fs-2">
                            Compliance
                        </div>
                        Stay compliant through our enterprise level security and industry client file management best practices.
                    </div>
                </div>
            </div>

            {/* section 1,1 end */}

            {/* section 2 */}
            <div >
                <div className="container-xl py-5 " id="about">
                    <div className="my-5 py-5"  >
                        <h1 className="fw-bold text-center text-primary" data-aos="fade-up">
                            Who We Are ?
                        </h1>
                        <div className="row justify-content-between my-5 ">
                            <div className="col-7 my-auto" data-aos="fade-right">
                                <div className="fs-3">
                                    We help Immigration Practitioners automate their firm
                                    Originally launched in Dec 2021, our secure all-in-one cloud solution simplifies the complexities in managing an immigration firm and helps increase revenues.
                                </div>
                            </div>
                            <div className="col-3 text-primary" data-aos="fade-left">
                                <i className="fas fa-cloud" style={{ fontSize: 270 }} />
                            </div>
                        </div>
                        <div className=" my-5 py-5" data-aos="fade-up">

                            <h2 className="text-primary font-weight-bold my-4">
                                Sign-up today
                            </h2>
                            <div className='text-dark mb-5'>
                                Let's get you on the journey to fully automating and managing your immigration practice.
                            </div>
                            <div className="row justify-content-evenly gx-3">
                                <div className="col-3">
                                    <VfInput
                                        icon={<i class="fas fa-file-signature"></i>}
                                        showLabel
                                        required
                                        label="Full Name *" value={name} inputHandler={(e) => setName(e.target.value)} />
                                </div>
                                <div className="col-3">
                                    <VfInput
                                        icon={<i class="far fa-envelope"></i>}
                                        showLabel
                                        required
                                        label="Your Work Email *" value={email} inputHandler={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="col-3">
                                    <VfInput
                                        icon={<i class="far fa-building"></i>}
                                        showLabel
                                        required
                                        label="Company Name *" value={company} inputHandler={(e) => setCompany(e.target.value)} />
                                </div>
                                <div className="col-3 my-auto">
                                    <div className="my-auto">
                                        <button className="btn btn-primary br" onClick={submitSignUpForm} disabled={formSubmitting}>
                                            {formSubmitting ? "Submitting..." : "Sign me up"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 2 end */}
            {/* section 3 */}
            <div style={{ background: '' }} id="feature" className="py-4">
                <div className="container-xxl text-center my-5 py-2" >
                    <div className="my-3 mt-5" data-aos="fade-up">
                        <h1 className="fw-bold text-primary">
                            Immigration case management, made easy!
                        </h1>
                        Our software helps you grow and automate your immigration business.
                        <div className="fw-bold link">
                            Watch these short demo videos to see how
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-9">
                            <div className="row g-3 my-3 py-5">
                                {[
                                    { title: 'Client File Management', desc: 'Manage your clients using our easy to use Client File Management features.' },
                                    { title: 'Immigration Forms Automation', desc: 'Access the latest immigration forms and populate them with ease.' },
                                    { title: 'Billing & Invoicing', desc: 'Collect payments faster with our built-in credit card payments processing.' },
                                    { title: 'Documents Management', desc: 'Manage all your clients documents in one place and add notes and feedback for them.' },
                                    { title: 'Retainer & Letter Automation', desc: 'Create your templates once, then have them automated for all clients.' },
                                    { title: 'Client Portal', desc: 'Your clients can access their invoices, make payments, submit documents and more using any mobile device.' },
                                    { title: 'Assessment/Intake Questionnaires', desc: 'Use our advanced assessment and intake questionnaire to collect the right information needed from your clients.' },
                                    { title: 'Client/Trust Account Reconciliation', desc: 'Reconcile your Client Accounts with a few clicks using our advanced reconciliation module.' },
                                    { title: 'Appointment Booking', desc: 'Schedule appointments with ease using our built-in calendaring system.' },
                                ].map(d => <VideoCard d={d} />)}
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 2 end */}
            </div>
            {/* section 3 end*/}


            {/* section 4 */}
            <div >
                <div className="container-xl py-5" id="pricing">
                    <div className="my-5"  >
                        <h1 className="fw-bold text-center my-5" data-aos="fade-up">
                            See our pricing
                        </h1>
                        <div className="text-center" data-aos="fade-up">
                            <h3>
                                Risk-free 30 days guarantee
                            </h3>
                            <div className="fw-normal">
                                If you‘re not 100% satisfied, let us know in the first 30-days for a full refund.
                            </div>
                        </div>
                        <PricingComponent />
                    </div>
                </div>
            </div>
            {/* section 2 end */}
        </div >
    )
}

const VideoCard = ({ d }) => {
    return (
        <div className="col-4 my-2 " data-aos="fade-up" >
            <div className="shadow hoverable bg-white br overflow-hidden video-card" style={{ height: 250 }}>
                <div className="py-5 bg-prim" style={{ background: 'url(https://picsum.photos/536/354)' }}>
                    <div className="py-3"></div>
                </div>
                <div className="px-1">
                    <div className="fw-bold mt-3">
                        {d.title}
                    </div>
                    <div className="mb-4">
                        {d.desc}
                    </div>
                </div>
            </div>
        </div>)
}