import React from 'react'

function EmailPage() {
    return (
        <div>
            <div style={{ minHeight: '100vh' }}>
                <div className="fw-bold">
                    Email Page
                </div>

            </div>
        </div>
    )
}

export default EmailPage
