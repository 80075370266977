import React, { useState, useEffect } from 'react'


function VfInput({ type,
    inputHandler,
    placeholder,
    error,
    value,
    isDisable,
    ref,
    name,
    color,
    icon,
    id, required, label, showLabel, optional, ...props }) {
    const [showPassword, setshowPassword] = useState(false)
    const style = {
        inputContainer: {
            // paddingLeft: 15,
            padding: 2,
            overflow: "hidden",
            width: "100%",
            outline: "none",
            border: error ? "2px solid #f00" : "2.5px solid var(--primary)",
            borderRadius: "10px",
            height: 50,
            transition: "all .3s ease-in-out",
            background: '#fff'
        },
        input: {
            outline: "none",
            border: "none",
            width: '80%',
            // height: 
        },
        icon: {
            width: '10%',
            color: 'var(--primary)',
            opacity: 0.7
        },
        password: {
            width: "10%"
        },
        label: {
            color: color === "secondary" ? "#fff" : 'var(--primary)',
            fontWeight: 700,
            opacity: 0.7
        }
    };
    return (
        <div className="my-2 w-100">
            {
                showLabel &&
                <div style={style.label} className="my-1 ms-2">
                    <div className="d-inline-block pg-text__bold ">{label}</div>
                    {required && <span className="textc-primary fst-italic"> (Required)</span>}
                    {optional && !isDisable && <span className="textc-primary fst-italic"> (Optional)</span>}
                </div>
            }
            <div >
                <div
                    className="d-flex flex-row"
                    style={style.inputContainer}
                >
                    {icon && <div className="d-inline-block text-center fs-4 my-auto" style={style.icon}>
                        {icon}
                    </div>}
                    {type === "textarea"
                        ? <textarea
                            placeholder={placeholder}
                            type={showPassword ? "text" : type}
                            style={style.input}
                            onInput={inputHandler}
                            name={name}
                            value={value}
                            disabled={isDisable}
                            id={id}
                            {...props}
                        />
                        : <input
                            {...props}
                            name={name}
                            placeholder={placeholder}
                            type={showPassword ? "text" : type}
                            style={style.input}
                            onInput={inputHandler}
                            value={value}
                            disabled={isDisable}
                            id={id}
                        />}
                    {type === "password" && <div className="d-inline-block text-center ms-auto my-auto" style={style.password} onClick={() => setshowPassword(!showPassword)}>
                        {!showPassword
                            ? <i class="far fa-eye-slash"></i>
                            : <i class="far fa-eye"></i>}
                    </div>}
                </div>
                <div
                    className="text-danger mx-2 my-1 transition-all"
                    style={error ? { opacity: 1 } : { opacity: 0 }}
                >
                    <i class="fas fa-exclamation-triangle me-2" ></i> {error}
                </div>
            </div>
        </div>
    )
}

export default VfInput
