import React, { useState } from 'react'
function PricingComponent() {
    const [isMonthly, setisMonthly] = useState(true)
    return (
        <div data-aos="fade-up">
            <div className="text-center mt-5 transition-all">
                <button
                    style={{ borderTopLeftRadius: 12 }}
                    onClick={() => setisMonthly(true)}
                    className={`transition-all fw-bold btn btn-sm mx-0 ${isMonthly && 'btn-primary'}`}>Monthly</button>
                <button
                    style={{ borderBottomRightRadius: 12 }}
                    onClick={() => setisMonthly(false)}
                    className={`transition-all fw-bold btn btn-sm mx-0 ${!isMonthly && 'btn-primary'}`}>Annually</button>
            </div>
            <div className="row justify-content-center">
                <div className="col-10">
                    <div className="row g-0 justify-content-center my-5">
                        <div className="col-3 px-0" data-aos="fade-right">
                            <div className="card p-3 text-center br-lg video-card">
                                <div className='fw-bold my-1 mt-3'>
                                    STARTER
                                </div>
                                <div className="my-0">
                                    For the startup firm
                                </div>
                                <div className="mx-auto text-info my-4 shadow d-inline-block" style={{ borderRadius: "50%", height: 80, width: 80, fontSize: 50 }}>
                                    <i className="far fa-user" />
                                </div>
                                <div className="fw-bold transition-all">
                                    <sup>$</sup><span style={{ fontSize: 60 }}>{isMonthly ? '79' : '59'}</span>
                                </div>
                                <div>
                                    Per month (billed annually)
                                    <div>
                                        Price in CAD
                                    </div>
                                </div>
                                <button className="btn btn-primary br my-4">Signup</button>
                            </div>
                        </div>
                        <div className="col-4 px-0 " style={{ zIndex: 1 }} data-aos="fade-up">
                            <div className="card p-3 text-center br-lg video-card shadow" >
                                <div className='fw-bold my-1 mt-3'>
                                    PREMIUM
                                </div>
                                <div className="my-0">
                                    For the professional
                                </div>
                                <div className="mx-auto my-4 text-success shadow d-inline-block" style={{ borderRadius: "50%", height: 80, width: 80, fontSize: 50 }}>
                                    <i className="far fa-gem" />
                                </div>
                                <div className="fw-bold transition-all">
                                    <sup>$</sup><span style={{ fontSize: 60 }}>{isMonthly ? '89' : '69'}</span>
                                </div>
                                <div>
                                    Per month (billed annually)
                                    <div>
                                        Price in CAD
                                    </div>
                                </div>
                                <button className="btn btn-primary br my-4">Signup</button>
                            </div>
                        </div>
                        <div className="col-3 px-0" data-aos="fade-left">
                            <div className="card p-3 text-center br-lg video-card">
                                <div className='fw-bold my-1 mt-3'>
                                    ULTIMATE
                                </div>
                                <div className="my-0">
                                    For mid-sized and large firms
                                </div>
                                <div className="mx-auto text-info my-4 shadow d-inline-block" style={{ borderRadius: "50%", height: 80, width: 80, fontSize: 50 }}>
                                    <i className="far fa-building mt-2" />
                                </div>
                                <div className="fw-bold transition-all">
                                    <sup>$</sup><span style={{ fontSize: 60 }}>{isMonthly ? '129' : '99'}</span>
                                </div>
                                <div>
                                    Per month (billed annually)
                                    <div>
                                        Price in CAD
                                    </div>
                                </div>
                                <button className="btn btn-primary br my-4">Signup</button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default PricingComponent
