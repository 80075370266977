import React from 'react';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import './App.css';
import './styles/checkbox.css';
import { MainRouter } from './routes/MainRouter';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'

function App() {
  const qc = new QueryClient();
  return (
    <div className="App">
      <QueryClientProvider client={qc}>
        <Router>
          <MainRouter />
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
