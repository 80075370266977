import React, { useState } from "react";
import Popup from "../../component/Practitioner/Calendar/Popup";
import Schedular from "../../component/Practitioner/Calendar/Schedular";

function CalenderPage() {
  const [showPopUp, setShowPopUp] = useState(false);
  return (
    <div>
      <div style={{ minHeight: "100vh" }}>
        <div
          className="btn btn-primary float-end"
          onClick={() => setShowPopUp((prev) => !prev)}
        >
          Task Scheduler
        </div>
        <br />
        <Popup show={showPopUp} onHide={() => setShowPopUp(false)} />
        <Schedular />
      </div>
    </div>
  );
}

export default CalenderPage;
