import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router'
import VfCheckBox from '../../utils/VfCheckBox'
import VfCheckBoxOnly from '../../utils/VfCheckBoxOnly'

function CaseTableItem({ data }) {
    const h = useHistory()
    console.log(data)
    return (
        <div className="d-flex justify-content-start w-100 py-1" style={{ borderBottom: '1px solid #d1d2d3' }}>
            <div className="my-auto" style={{ width: '5%' }}>
                <VfCheckBoxOnly />
            </div>
            <div className="my-auto" style={{ width: '30%' }}>
                {data?.client?.fName}
                {data?.client?.lName} <div className="badge bg-secondary"> {data.caseType}</div>
            </div>
            <div className="my-auto" style={{ width: '30%' }}>
                {data?.client?.clientType}
            </div>
            <div className="my-auto" style={{ width: '30%' }}>
                {data?.client?.status}
            </div>
            <div className="my-auto" style={{ width: '30%' }}>
                {moment(data.createdAt).fromNow()}
            </div>
            <div className="my-auto" style={{ width: '15%' }}>
                <button className="btn btn-primary" onClick={() => h.push('/practitioner/cases/single_case', data)}>View Case</button>
            </div>
            {/* {JSON.stringify(data)} */}
            <hr />
        </div>
    )
}

export default CaseTableItem
