import { applyMiddleware, createStore } from "redux";
import storage from "redux-persist/es/storage";
import { persistReducer, persistStore } from "redux-persist";
import { apiMiddleware } from "redux-api-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import toast from 'react-hot-toast';

import { routerMiddleware } from "connected-react-router";
import interceptor from "redux-api-middleware-interceptor";
import vfReducer from "./reducers";
import _ from "lodash";
// import

const storeMain = (history) => {
  const reducer = persistReducer(
    {
      timeout: null,
      key: process.env.REACT_APP_STORE_KEY,
      whitelist: [
        "auth", "router"
      ],
      storage: storage,
    },
    vfReducer(history)
  );

  const store = createStore(
    reducer,
    composeWithDevTools(
      applyMiddleware(
        interceptor({
          headers: (origHeaders, state) => {
            const headers = Object.assign({}, origHeaders);
            if (state.auth.isAuth) {
              // headers['Authorization'] = `Bearer ${state.auth.token}`;
              headers['Current-Domain'] = state.auth.currentPractice;
              headers['Current-Type'] = state.auth.userType;
            }
            return headers;
          },
          onRequestError: (state, response) => {
            if (response.msg)
              toast.error(response.msg)
            else {
              console.log('Handles Global Error:', response);
              toast.error("Something went wrong!!")

            }
          },
        }),
        apiMiddleware,
        // thunk,
        routerMiddleware(history),
      )
    )
  );

  persistStore(store);
  return store;
};

export default storeMain;
