import React from 'react'

function CDashboard() {
    return (
        <div style={{ minHeight: '100vh' }}>
            <h2>Customer Dashboard</h2>
        </div>
    )
}

export default CDashboard
