import React, { useState, useEffect } from 'react'
import { Link,useHistory } from 'react-router-dom'
import Logo from '../assets/logo.png'

function Header() {
    const h=useHistory();
    const [headerClasses, setheaderClasses] = useState('')
    useEffect(() => {
        window.onscroll = (e) => {
            let scrolled = window.pageYOffset;
            if (scrolled > 100)
                setheaderClasses('shadow bg-white')
            else setheaderClasses('')
        }
        return () => {
            window.onscroll = null;
        }
    }, [])
    return (
        <div className={`position-fixed w-100 top-0 transition-all ${headerClasses}`} style={{ zIndex: 9, top: 0 }}>
            <div className="container-xxl pt-1">
                <div className="row">
                    <Link className="col-2" to="/">
                        <img src={Logo} alt="" style={{ width: 120, height: 60 }} />
                    </Link>
                    <div className="col-10 my-auto text-right">
                        <div className="d-flex justify-content-end ">
                            <a href="#" className="mx-0 btn btn-link text-dark fw-bold mt-2">Home</a>
                            <a href="#about" className="mx-0 btn btn-link text-dark fw-bold mt-2">About</a>
                            <a href="#feature" className="mx-0 btn btn-link text-dark fw-bold mt-2">Features</a>
                            <a href="#pricing" className="mx-0 btn btn-link text-dark fw-bold mt-2">Pricing</a>
                            <button onClick={()=>h.push("/practitioner")} className="mx-1 btn btn-rounded btn-primary fw-bold br">Get started</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
