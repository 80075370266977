import React, { useState, useEffect } from 'react'
import SettingCard from '../../component/Practitioner/Settings/SettingCard'
import { BoldOutlined, CustomerServiceOutlined, ContactsOutlined } from '@ant-design/icons'

function PracSettingsPage() {
    const [settingMenu, setsettingMenu] = useState([{
        icon: <ion-icon name="library-outline"></ion-icon>,
        title: "Branding"
    },
    {
        icon: <ion-icon name="briefcase-outline"></ion-icon>,
        title: "Case Info"
    },
    {
        icon: <ion-icon name="call-outline"></ion-icon>,
        title: "Contact Info"
    },
    {
        icon: <ion-icon name="people-outline"></ion-icon>,
        title: "Client Account"
    },
    {
        icon: <ion-icon name="git-branch-outline"></ion-icon>,
        title: "Integration"
    },
    {
        icon: <ion-icon name="people-outline"></ion-icon>,
        title: "Tax Information"
    },
    {
        icon: <ion-icon name="code-slash-outline"></ion-icon>,
        title: "Templates"
    },
    ])
    return (
        <div>
            <div style={{ minHeight: '100vh' }}>
                <div className="container-xxl">
                    <div className="row g-4">
                        {
                            settingMenu.map(sm => {
                                return <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                    <SettingCard
                                        icon={sm.icon}
                                        title={sm.title} />
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PracSettingsPage

