import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
import 'aos/dist/aos.css';
// import 'mdbreact/dist/css/mdb.css';
import './index.css';
import App from './App';
import configureStore from './store';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Toaster } from 'react-hot-toast';
import { createBrowserHistory } from 'history'
import ScrollToTop from './component/ScrollToTop';
import AOS from 'aos';

const history = createBrowserHistory();
export const store = configureStore(history);


AOS.init({
  duration : 2000
})

ReactDOM.render(
  <Provider store={store}>
    <App />
    {/* <ScrollToTop /> */}
    <Toaster
      position="bottom-left"
      reverseOrder={false}
    />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
