import React from 'react'

function CaseBillingPage() {
    return (
        <div>
            Case Billing Page
        </div>
    )
}

export default CaseBillingPage
