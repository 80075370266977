import * as ui from "../actions/ui";

const intialState = {
  currentPractPage: "Welcome",
};
const uiReducer = (state = intialState, actions) => {
  switch (actions.type) {
    case ui.CHANGE_UI_STATUS:
      return {
        ...state,
        ...actions.payload
      };
    default:
      return state;
  }
};

export default uiReducer;
