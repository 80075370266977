import React, { useState } from 'react'
import { Avatar, Tooltip, Card, Badge } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClient } from '../../../store/actions/ClientAndProspects';
import toast from 'react-hot-toast';
import CreateNewCaseModal from '../Case/CreateNewCaseModal';
import VfPrompt from '../../utils/VfPrompt';


function ClientCard({ data, index, refereshPage }) {
    const h = useHistory();
    const token = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const [showCaseModal, setshowCaseModal] = useState(false);
    const [showDeletePrompt, setshowDeletePrompt] = useState(false)
    const [deletingClient, setdeletingClient] = useState(false)
    const deleteCaseFn = async (c_id) => {
        setdeletingClient(true)
        const response = await dispatch(deleteClient(c_id, token))
        setdeletingClient(false)
        if (!response.error) {
            refereshPage();
            setshowDeletePrompt(false)
            toast.success('Case deleted Successfully!')
        }
    }
    return (
        <Badge.Ribbon text={data?.clientType} color={`${data?.clientType === "prospects" ? 'orange' : 'green'}`}>
            <Card className="shadow  br-lg m-1 p-2 position-relative client-card" hoverable>
                {showCaseModal && <CreateNewCaseModal
                    show={showCaseModal}
                    fromCAP={true}
                    aemail={data?.email}
                    afname={data?.fName}
                    alname={data?.lName}
                    aid={data?._id}
                    handleClose={() => setshowCaseModal(false)}
                />}
                {showDeletePrompt && <VfPrompt
                    show={showDeletePrompt}
                    onNo={() => setshowDeletePrompt(false)}
                    onYes={() => deleteCaseFn(data?._id)}
                    text="Are you sure to delete the client?"
                    loading={deletingClient}
                />}
                <div className="">
                    <div className="position-absolute bg-primary text-light px-2 text-center" style={{ borderRadius: '50%', top: -10, left: -10 }}>
                        {index + 1}
                    </div>
                    <div className="row gx-5 my-2 mb-3">
                        <div className="col-4 my-auto text-center">
                            <Avatar
                                className=""
                                size={{ xs: 80, sm: 80, md: 60, lg: 80, xl: 80, xxl: 80 }}
                                icon={<UserOutlined />}
                            />
                        </div>
                        <div className="col-8 text-start">
                            <Tooltip title="Click To view client detail">
                                <div className="text-ellipsis" >
                                    Name: <span className='fw-bold text-primary cp overflow-hidden' style={{ textOverflow: 'ellipsis' }}>{data.fName} {data.lName}</span>
                                </div>
                            </Tooltip>
                            <div>
                                Status: <b className="text-capitalize">{data?.status} {data?.status === 'active' && <span className="online-dot"></span>}</b>
                            </div>
                            <div>
                                Age: <b>{moment().diff(data?.dob, 'years')}</b>
                            </div>
                            <div>
                                Current Cases: <b>{data?.cases?.length}</b>
                            </div>
                        </div>
                    </div>
                    <div className="row g-1">
                        <div className="col-6">
                            <button
                                className="w-100 btn btn-sm btn-secondary " onClick={() => h.push(`/practitioner/client_and_prospects/add_cases`, { editable: true, client_id: data?._id })}>
                                <ion-icon name="pencil-outline"></ion-icon> Edit
                            </button>
                        </div>
                        <div className="col-6">
                            <button className="w-100 btn btn-sm btn-secondary">
                                <a href={`tel:${data?.phone}`} className="text-primary">
                                    <ion-icon name="call-outline"></ion-icon> Call </a>
                            </button>

                        </div>
                        <div className="col-6">
                            <button className=" w-100 btn btn-sm btn-secondary" onClick={() => setshowDeletePrompt(true)}>
                                <ion-icon name="trash-bin-outline"></ion-icon> Delete
                            </button>

                        </div>
                        <div className="col-6">
                            <button className="w-100 btn btn-sm btn-secondary" onClick={() => setshowCaseModal(true)}>
                                <ion-icon name="camera-outline"></ion-icon> New Case
                            </button>
                        </div>
                    </div>
                    <button className="btn btn-primary btn-sm w-100 my-2" style={{ height: 30 }}
                        onClick={() => h.push(`/practitioner/client_and_prospects/view?id=${data?._id}`, data)}
                    >View Client</button>
                    <hr className="my-0" />
                    <div className="py-2 text-ellipsis">
                        email : <b> {data?.email}</b>
                    </div>
                </div>
            </Card>
        </Badge.Ribbon>
    )
}

export default ClientCard
