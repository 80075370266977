import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';
import { getCasesForCustomer } from '../../store/actions/case';
import CCaseCard from '../../component/Client/CCaseCard';

function CCasesPage() {
    const token = useSelector(state => state.auth.token);

    const { isLoading, data, refetch } = useQuery('clientList', () => getCasesForCustomer(token));

    return (
        <div>
            <h5>Customer Case Page</h5>

            <div>
                {isLoading
                    ? 'Loading...'
                    : <div className="row g-2">
                        {data?.data?.data.map(caseData => {
                            return <CCaseCard data={caseData} />
                        })}
                    </div>
                }
            </div>
        </div>
    )
}

export default CCasesPage
