import React from 'react';
import { Route, Redirect, useLocation } from "react-router-dom";

export const AdminRoute = ({ component: Component,
    layout: Layout,
    isAuth: isAuthenticated,
    userType,
    ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (isAuthenticated && userType === "admin")
                    return <Layout>
                        <Component {...props} />
                    </Layout >
                else return <Redirect to={{ pathname: "/static/login" }} />

            }}
        />
    )
}

