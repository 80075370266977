import { createAction } from "redux-api-middleware";
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API


export const SIGNUPFORM_REQUEST = "SIGNUPFORM_REQUEST";
export const SIGNUPFORM_SUCCESS = "SIGNUPFORM_SUCCESS";
export const SIGNUPFORM_FAILURE = "SIGNUPFORM_FAILURE";

export const getClients = (url, token) => {
    return axios.get(`${BASE_URL}/api/v1/user/client${url}`, { headers: { Authorization: `Bearer ${token}` } });
}

// export const getClient = (client_id, token) => {
//     return axios.get(`${BASE_URL}/api/v1/user/client/${client_id + 1}`, { headers: { Authorization: `Bearer ${token}` } });
// }

export const getClient = (client_id, token) => {
    return createAction({
        endpoint: `${BASE_URL}/api/v1/user/client/${client_id}`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        types: [
            SIGNUPFORM_REQUEST,
            SIGNUPFORM_SUCCESS,
            SIGNUPFORM_FAILURE,
        ],
    });
};

export const deleteClient = (client_id, token) => {
    return createAction({
        endpoint: `${BASE_URL}/api/v1/user/client/${client_id}`,
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        types: [
            SIGNUPFORM_REQUEST,
            SIGNUPFORM_SUCCESS,
            SIGNUPFORM_FAILURE,
        ],
    });
};


export const createClient = (data, token) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/user/client`,
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
        types: [
            SIGNUPFORM_REQUEST,
            SIGNUPFORM_SUCCESS,
            SIGNUPFORM_FAILURE,
        ],
    });
};

export const updateClientStatus = (data, token) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/user/client/status`,
        method: "PUT",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
        types: [
            SIGNUPFORM_REQUEST,
            SIGNUPFORM_SUCCESS,
            SIGNUPFORM_FAILURE,
        ],
    });
};

export const updateClient = (data, token) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/user/client`,
        method: "PUT",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
        types: [
            SIGNUPFORM_REQUEST,
            SIGNUPFORM_SUCCESS,
            SIGNUPFORM_FAILURE,
        ],
    });
};


export const createClientPassword = (data, token) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/user/client/reset_password`,
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json", 'Authorization': token },
        types: [
            SIGNUPFORM_REQUEST,
            SIGNUPFORM_SUCCESS,
            SIGNUPFORM_FAILURE,
        ],
    });
};


