import React from 'react'

function ContactsPage() {
    return (
        <div>
            <div style={{ minHeight: '100vh' }}>
                COntanct Page
            </div>
        </div>
    )
}

export default ContactsPage
