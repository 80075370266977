import Footer from "../component/Footer"
import Header from "../component/Header"

function HomePageLayout({ children }) {
    return (
        <div>
            <Header />
            <div className="">
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default HomePageLayout
