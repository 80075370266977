import React from 'react'

function CaseFormPage() {
    return (
        <div>
           Case Form Page 
        </div>
    )
}

export default CaseFormPage
