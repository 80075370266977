import { Breadcrumb } from 'antd';
import React, { useEffect ,useState} from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom';
import VfInput from '../../../component/utils/VfInput';
import VfSelect from '../../../component/utils/VfSelect';

function CreateNewCase() {
    const l = useLocation();
    const [email, setemail] = useState("");
    const [fName, setfName] = useState("");
    const [lName, setlName] = useState("");
    useEffect(() => {

    }, [])
    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <NavLink to="/practitioner/cases">
                        Cases
                    </NavLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Create New Cases
                </Breadcrumb.Item>
            </Breadcrumb>,
            <div className="container-xxl">
                Case Information
                <div className="row">
                    <div className="col-6">
                        <VfSelect
                            showLabel
                            label="Case Type"
                        />
                        <VfInput
                            showLabel
                            label="Email"
                            value={email}
                        />
                        <VfInput
                            showLabel
                            label="fName"
                            value={email}
                        />
                        <VfInput
                            showLabel
                            label="Email"
                            value={email}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateNewCase
