import React, { useState, useEffect } from 'react'
import PracFooter from '../component/Practitioner/PracFooter'
import PracHeader from '../component/Practitioner/PracHeader'
import SideNavigation from '../component/Client/SideNavigation'

function CustomerLayout({ children }) {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const collapseSidebar = () => {
        setIsCollapsed(!isCollapsed)
    }
    return (
        <div>
            <div className="d-flex">
                <div className={`transition-all navbar-container ${isCollapsed && 'collapsed'}`} >
                    <SideNavigation collapseSidebar={collapseSidebar} isCollapsed={isCollapsed} />
                </div>
                <div className={`main-container ${isCollapsed && 'collapsed'}`}>
                    <PracHeader />
                    <main id="" className="p-2  my-5 pt-4" >
                        {children}
                    </main>
                    <PracFooter />
                </div>
            </div>
        </div>
    )
}

export default CustomerLayout
