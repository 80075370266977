import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, NavLink, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';

// import { changeCurrentPage } from '../store_/ui';
import logo from "../../assets/logo.png";
import icon from "../../assets/icon.png";
import { changeUIStatus } from '../../store/actions/ui';

function SideNavigation({ collapseSidebar, isCollapsed }) {
    return (
        <div className="sidebar position top-0 left-0 bg-light" >
            <div className="position-relative">
                <NavLink className="my-3 fw-bolder text-center text-white fs-1 " to="/">
                    {isCollapsed ? <img className="position-relative" src={icon} style={{ left: 8, top: -20, height: 40, width: 40 }} /> : <img src={logo} style={{ height: 100, width: 160 }} />}
                </NavLink>
                <div className="prac-menu__arrow fs-2 transition-all" onClick={collapseSidebar} style={{ transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)" }}>
                    <ion-icon name="chevron-back-circle-outline" ></ion-icon>
                </div>
                {menuItems.map(mi => {
                    return <MenuItem
                        icon={mi.icon}
                        name={mi.name}
                        to={mi.to}
                        isCollapsed={isCollapsed}
                    />
                })}
            </div>
        </div >
    );
}

export default SideNavigation

export const menuItems = [
    {
        name: "Dashboard",
        to: "/admin/dashboard",
        icon: <ion-icon name="speedometer-outline"></ion-icon>,
    },
    {
        name: "Settings",
        to: "/practitioner/settings",
        icon: <ion-icon name="settings-outline"></ion-icon>,
    },
    {
        name: "Account",
        to: "/practitioner/account",
        icon: <ion-icon name="wallet-outline"></ion-icon>,
    },
];

const MenuItem = (props) => {
    const dispatch = useDispatch()
    const { name, icon, to, isCollapsed } = props;
    return (
        <NavLink exact={name.toLowerCase() === "homepage"} to={to} className={`d-block prac-menu__item`} onClick={() => dispatch(changeUIStatus({ currentPractPage: name }))}>
            <div className="prac-menu__icon d-inline-block mx-2 ">
                <Tooltip placement="rightTop" title={name}>
                    {icon}
                </Tooltip>
            </div>
            {!isCollapsed && <div className="prac-menu__name d-inline-block">{name}</div>}
        </NavLink>
    );
};


