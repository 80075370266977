import React, { useState, useEffect } from 'react'
import logo from "../assets/logo.png";
import icon from "../assets/icon.png";
import { useHistory, useLocation, NavLink, Link } from 'react-router-dom';
// import { changeCurrentPage } from '../store_/ui';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { changeUIStatus } from '../store/actions/ui';
function SideNavigation({ collapseSidebar, isCollapsed }) {

    return (
        <div className="sidebar position top-0 left-0 bg-light" >
            <div className="position-relative">
                <NavLink className="my-3 fw-bolder text-center text-white fs-1 " to="/">
                    {isCollapsed ? <img className="position-relative" src={icon} style={{ left: 8, top: -20, height: 40, width: 40 }} /> : <img src={logo} style={{ height: 100, width: 160 }} />}
                </NavLink>
                <div className="prac-menu__arrow fs-2 transition-all" onClick={collapseSidebar} style={{ transform: isCollapsed ? "rotate(180deg)" : "rotate(0deg)" }}>
                    <ion-icon name="chevron-back-circle-outline" ></ion-icon>
                </div>
                {menuItems.map(mi => {
                    return <MenuItem
                        icon={mi.icon}
                        name={mi.name}
                        to={mi.to}
                        isCollapsed={isCollapsed}
                        collapseSidebar={collapseSidebar}
                    />
                })}
            </div>
        </div >
    );
}

export default SideNavigation

export const menuItems = [
    {
        name: "Homepage",
        to: "/practitioner",
        icon: <ion-icon name="home-outline"></ion-icon>,
    },
    {
        name: "Dashboard",
        to: "/practitioner/dashboard",
        icon: <ion-icon name="speedometer-outline"></ion-icon>,
    },
    {
        name: "Cases",
        to: "/practitioner/cases",
        icon: <ion-icon name="folder-open-outline"></ion-icon>,
    },
    {
        name: "Clients & Prospects",
        to: "/practitioner/client_and_prospects",
        icon: <ion-icon name="people-outline"></ion-icon>,
    },
    {
        name: "Emails",
        to: "/practitioner/emails",
        icon: <ion-icon name="mail-outline"></ion-icon>,
    },
    {
        name: "Tasks",
        to: "/practitioner/tasks",
        icon: <ion-icon name="list-outline"></ion-icon>,
    },
    {
        name: "Calendar",
        to: "/practitioner/calender",
        icon: <ion-icon name="calendar-outline"></ion-icon>,
    },
    {
        name: "Contacts",
        to: "/practitioner/contacts",
        icon: <ion-icon name="call-outline"></ion-icon>,
    },
    {
        name: "Reporting",
        to: "/practitioner/reporting",
        icon: <ion-icon name="bar-chart-outline"></ion-icon>,
    },
    {
        name: "Settings",
        to: "/practitioner/settings",
        icon: <ion-icon name="settings-outline"></ion-icon>,
    },
    {
        name: "Account",
        to: "/practitioner/account",
        icon: <ion-icon name="wallet-outline"></ion-icon>,
    },
];

const MenuItem = (props) => {
    const dispatch = useDispatch()
    const { name, icon, to, isCollapsed, collapseSidebar } = props;
    return (
        <NavLink exact={name.toLowerCase() === "homepage"} to={to} className={`d-block prac-menu__item`} onClick={() => { if (!isCollapsed) collapseSidebar(); dispatch(changeUIStatus({ currentPractPage: name })) }}>
            <div className="prac-menu__icon d-inline-block mx-2 ">
                <Tooltip placement="rightTop" title={name}>
                    {icon}
                </Tooltip>
            </div>
            {!isCollapsed && <div className="prac-menu__name d-inline-block">{name}</div>}
        </NavLink>
    );
};


