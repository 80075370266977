import React from 'react';
import { Route, Redirect, useLocation } from "react-router-dom";

export const LoginRoute = ({ component: Component,
    layout: Layout,
    isAuth: isAuthenticated,
    userType,
    ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (isAuthenticated) {
                    if (userType === "practitioner")
                        return <Redirect to={{ pathname: "/practitioner" }} />
                    if (userType === "admin")
                        return <Redirect to={{ pathname: "/admin/dashboard" }} />
                    if (userType === "customer")
                        return <Redirect to={{ pathname: "/customer/dashboard" }} />
                } else {
                    return (<Layout>
                        <Component {...props} />
                    </Layout>)
                }
            }}
        />
    )
}

