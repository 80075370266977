import { useState, useEffect } from 'react'
import LImage from '../../assets/static/login_2.png';
import Logo from '../../assets/logo.png'
import { useDispatch, useSelector } from 'react-redux';
import { authenticate, changeAuthState, searchSubdomain } from '../../store/actions/auth'
import { NavLink, Redirect, useHistory } from 'react-router-dom';
import VfInput from '../../component/utils/VfInput';
import { Spin, Space } from 'antd';
import VfInputLabel from '../../component/utils/VfInputLabel';
import toast from 'react-hot-toast';
import { parse } from 'query-string'
import _ from 'lodash';
import { createClientPassword } from '../../store/actions/ClientAndProspects';

export const ResetPasswordPage = () => {
    const h = useHistory()
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [token, settoken] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setconfirmPassword] = useState("")
    const [submittinPWD, setsubmittinPWD] = useState(false)
    const subdomain = useSelector(state => state.auth.currentSD)
    const [verifyingURL, setverifyingURL] = useState(true);
    const [searchingSD, setsearchingSD] = useState(true)
    const [inputSD, setinputSD] = useState("")
    const [sdExist, setsdExist] = useState(false)

    useEffect(() => {
        const pu = parse(window.location.href, { decode: false })
        console.log(pu, "pu")
        if (_.isEmpty(pu.email) || _.isEmpty(pu.token)) {
            toast.error('Invalid URL!')
            h.replace('/');
        } else {
            setEmail(pu.email);
            settoken(pu.token);
            setverifyingURL(false);
        }
    }, [])

    const createPasswordFn = async (e) => {
        e.preventDefault()
        const response = await dispatch(createClientPassword({ email, password, confirmPassword }, token));
        if (!response.error) {
            toast.success("Password Created Successfully!")
            h.replace('/user/login')
        }
    }

    return (
        <div style={{ background: `url(${LImage}) no-repeat fixed`, backgroundSize: 'cover', width: '100vw', height: '100vh' }}>
            <div>
            </div>
            <div>
                <div className="position-absolute h-100 br-lg login-card" style={{ width: 500,left:'0', }}>
                    <div className="position-relative">
                        <div className="position-absolute" style={{ top: 50, left: 20 }}>
                            <NavLink to="/" className="text-light fw-bold text-uppercase" >
                                <i className="fa fa-chevron-left me-2" /> homepage
                            </NavLink>
                        </div>
                    </div>
                    {
                        verifyingURL
                            ? <div
                                className="position-absolute"
                                style={{ top: "50%", left: "50%" }}>

                                <Spin size="large" className="text-primary" /></div>
                            : <div className="px-0">
                                <div className=" px-4 ">
                                    <div className="my-5 py-3"></div>
                                    <div className="text-center text-light">
                                        <img src={Logo} alt="" style={{ width: 180, height: 80 }} />
                                        {sdExist && <>
                                            <span className="fs-2 me-2 fw-bold">|</span>
                                            <div className="d-inline-block fs-2 fw-bold bg-dark gradient p-1 br" style={{ verticalAlign: 'sub', textTransform: 'capitalize' }}>
                                                {subdomain}
                                            </div>
                                        </>}
                                    </div>
                                    <form className="my-2" onSubmit={createPasswordFn} onReset={() => { setconfirmPassword(""); setPassword("") }}>

                                        <div className="mt-5">
                                            <VfInput
                                                icon={<i class="fas fa-at"></i>}
                                                showLabel
                                                label='Email'
                                                size='lg'
                                                className="my-2"
                                                outline
                                                required
                                                isDisable
                                                value={email}
                                                background
                                                color="secondary"
                                                inputHandler={(e) => setEmail(e.target.value)} />
                                            <VfInput
                                                background
                                                showLabel
                                                icon={<i class="fas fa-key"></i>}
                                                required
                                                value={password}
                                                label='Password'
                                                color="secondary"
                                                id='formControlLg'
                                                type='password'
                                                size='lg'
                                                inputHandler={(e) => setPassword(e.target.value)} />
                                            <VfInput
                                                background
                                                showLabel
                                                icon={<i class="fas fa-key"></i>}
                                                required
                                                value={confirmPassword}
                                                label='Confirm Password'
                                                color="secondary"
                                                id='formControlLg'
                                                type='password'
                                                size='lg'
                                                inputHandler={(e) => setconfirmPassword(e.target.value)} />
                                        </div>
                                        <div className="text-end">
                                            <button className="px-5 me-2 btn btn-warning br font-weight-bold" color="info" type="reset" >Reset</button>
                                            <button className="px-5 btn btn-light br font-weight-bold" color="light" type='submit'>{submittinPWD ? "Loading..." : "Create"}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                    }
                </div>
            </div>
        </div>
    )
}

// export default ResetPasswordPage
