import React, { Component } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { divide } from 'lodash';

class ChartSection2 extends Component {
    render() {
        const dataBar = {
            labels: ['Revenue', 'Recievables'],
            datasets: [
                {
                    label: '#1',
                    data: [12, 39, 3, 50, 2, 32, 84],
                    backgroundColor: 'rgba(245, 74, 85, 0.5)',
                    borderWidth: 1
                }, {
                    label: '#2',
                    data: [56, 24, 5, 16, 45, 24, 8],
                    backgroundColor: 'rgba(90, 173, 246, 0.5)',
                    borderWidth: 1
                }, {
                    label: '#3',
                    data: [12, 25, 54, 3, 15, 44, 3],
                    backgroundColor: 'rgba(245, 192, 50, 0.5)',
                    borderWidth: 1
                }
            ]
        };

        const barChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    barPercentage: 1,
                    gridLines: {
                        display: true,
                        color: 'rgba(0, 0, 0, 0.1)'
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        color: 'rgba(0, 0, 0, 0.1)'
                    },
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        }

        const dataPie = {
            labels: ['Active'],
            datasets: [
                {
                    data: [90,10],
                    backgroundColor: ['#bb8ab5',],
                    hoverBackgroundColor: ['rgb(40 66 87)']
                }
            ]
        }
        return (
            <div className="container-fluid my-4">
                <div className="row shadow">
                    <div className="col-8">
                        <div className="fw-bold mt-3">Cases by Type</div>
                        <div>
                            <Bar data={dataBar} height={500} options={barChartOptions} />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="fw-bold mt-3">Case Status</div>
                        <div>
                            <Pie data={dataPie} height={300} options={{ responsive: true }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChartSection2;

