import React from 'react'
function Footer() {
    return (
        <div className="bg-primary py-5">
            <div className="container-xxl">
                <div className="row justify-content-center">
                    <div className="col-2 text-start">
                        <div className="fw-bold text-light">
                            Features
                        </div>
                        <div className="my-2 px-0 d-block text-left text-light">
                            
                            <a href="#feature" className="text-light">Features</a>
                        </div>
                        <div className="my-2 px-0 d-block text-left text-light">
                            <a href="#pricing" className="text-light">Pricing</a>
                        </div>
                        <div className="my-2 px-0 d-block text-left text-light">
                            Help Center
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="fw-bold text-light text-start">
                            Company
                        </div>
                        <div className="my-2 d-block text-light">
                            <a href="#about" className="text-light">About us</a>
                        </div>
                        <div className="my-2 d-block text-light">
                            Career
                        </div>
                        <div className="my-2 d-block text-light">
                            Team
                        </div>
                        <div className="my-2 px-0 d-block text-left text-light">
                            Advisory Board
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="fw-bold text-light">
                            Terms
                        </div>
                        <div className="my-2 px-0 d-block text-left text-light">
                            Terms of service
                        </div>
                        <div className="my-2 px-0 d-block text-left text-light">
                            Privacy policy
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="fw-bold text-light">
                            Follow US
                        </div>
                        <div className="my-2 px-0 d-block text-left text-light">
                            <i className="fab fa-twitter mx-2" />
                            <i className="fab fa-facebook mx-2" />
                            <i className="fab fa-instagram mx-2" />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="fw-bold text-light">
                            Contacts
                        </div>
                        <div className="text-light">
                            <div>
                                Visafy
                            </div>
                            HEADQUARTERS HEAD OFFICE
                            5925 Airport Road, Suite 200
                            Mississauga, ON, L4V 1W1, Canada
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
