import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Tabs } from 'antd';
import { QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';

import CaseProfilePage from './CaseProfilePage';
import CaseQuestionnairePage from './CaseQuestionnairePage';
import CaseFormPage from './CaseFormPage';
import CaseDocumentPage from './CaseDocumentPage';
import CaseTasksPage from './CaseTasksPage';
import CaseBillingPage from './CaseBillingPage';
import CaseNotesPage from './CaseNotesPage';

const { TabPane } = Tabs;

function SingleCaseHomepage({ data }) {
    const h = useHistory();
    const l = useLocation();
    const [caseData, setcaseData] = useState(l.state)
    const [activeTab, setactiveTab] = useState("1")
    console.log('in case page', l.state)
    useEffect(() => {
        if (l.state.goToQ)
            setactiveTab("2")
    }, [])
    return (
        <div style={{ minHeight: '100vh' }}>
            <div className="my-2 mb-4 fw-bold text-primary cp" onClick={() => h.push('/practitioner/cases')}>
                <ion-icon name="arrow-back-outline"></ion-icon> <span style={{ verticalAlign: 'text-bottom' }}>Cases</span>
            </div>
            <Tabs activeKey={activeTab} defaultActiveKey="1" centered type="card" tabBarStyle={{ color: 'red' }} onChange={(e) => setactiveTab(e)}>
                <TabPane
                    tab={
                        <span className="fs-5">
                            <ion-icon name="person-circle-outline"></ion-icon>
                            Profile
                        </span>
                    }
                    key="1">
                    <CaseProfilePage caseId={caseData.id} activeTab={activeTab} />
                </TabPane>
                <TabPane
                    tab={
                        <span className="fs-5">
                            <ion-icon name="help-circle-outline"></ion-icon>
                            Questionnaire
                        </span>
                    }
                    key="2">
                    <CaseQuestionnairePage caseId={caseData.id} activeTab={activeTab} />
                </TabPane>
                <TabPane
                    tab={
                        <span className="fs-5">
                            <ion-icon name="document-attach-outline"></ion-icon>
                            Documents
                        </span>
                    }
                    key="3">
                    <CaseDocumentPage caseId={caseData.id} activeTab={activeTab} />
                </TabPane>
                <TabPane
                    tab={
                        <span className="fs-5">
                            <ion-icon name="layers-outline"></ion-icon>
                            Forms
                        </span>
                    } key="4">
                    <CaseFormPage caseId={caseData.id} activeTab={activeTab} />
                </TabPane>
                <TabPane
                    tab={
                        <span className="fs-5">
                            <ion-icon name="receipt-outline"></ion-icon>
                            Notes
                        </span>
                    }
                    key="6">
                    <CaseNotesPage />
                </TabPane>
                <TabPane
                    tab={
                        <span className="fs-5">
                            <ion-icon name="albums-outline"></ion-icon>
                            Tasks
                        </span>
                    } key="7">
                    <CaseTasksPage />
                </TabPane>
                <TabPane tab={
                    <span className="fs-5">
                        <ion-icon name="calculator-outline"></ion-icon>
                        Billing
                    </span>
                } key="8">
                    <CaseBillingPage />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default SingleCaseHomepage
