import React from 'react'

function PracFooter() {
    return (
        <div className="prac-footer bg-primary pt-4 pb-2">
            <div className="container-xxl">
                <div className="row justify-content-evenly">
                    <div className="col-4 text-light ms-5">
                        {new Date().toDateString()}
                        <div >
                            <a className="text-light-blue fw-bold" href="/">Visafy.ca
                                <i className="fas fa-external-link-alt ms-2" />
                            </a> | Copyright © 2021 | All rights reserved.
                        </div>
                    </div>
                    <div className="col-4 text-light">
                        Email Support: <a className="" href="mailto:support@visafy.ca">support@visafy.ca</a>
                        <div>
                            Support Hours: Mon-Fri 10AM-6PM EST
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PracFooter
