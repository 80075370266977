import { createAction } from "redux-api-middleware";

const BASE_URL = process.env.REACT_APP_API;

export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

export const SIGNUPFORM_REQUEST = "SIGNUPFORM_REQUEST";
export const SIGNUPFORM_SUCCESS = "SIGNUPFORM_SUCCESS";
export const SIGNUPFORM_FAILURE = "SIGNUPFORM_FAILURE";

export const LOGOUT = "LOGOUT";
export const CHANGE_AUTH = "CHANGE_AUTH";


export const authenticate = (email, password) => {
  let params = {
    email,
    password
  };
  return createAction({
    endpoint: BASE_URL + `/api/v1/user/login`,
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
    types: [
      AUTH_REQUEST,
      {
        type: AUTH_SUCCESS,
        payload: (action, state, res, req) => {
          const authToken = res.headers.get("Authorization");
          console.log(authToken, "authtoken", res, req)
          return res.json().then((json) => {
            return { ...json, token__: authToken };
          });
        },
      },
      // AUTH_SUCCESS,
      AUTH_FAILURE,
    ],
  });
};

export const signupForm = (name, email, company) => {
  let params = {
    email,
    name,
    company
  };
  return createAction({
    endpoint: BASE_URL + `/api/v1/user/signup`,
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
    types: [
      SIGNUPFORM_REQUEST,
      SIGNUPFORM_SUCCESS,
      SIGNUPFORM_FAILURE,
    ],
  });
};

export const searchSubdomain = (data) => {
  let subdomain = data;
  return createAction({
    endpoint: BASE_URL + `/api/v1/user/domain`,
    method: "POST",
    body: JSON.stringify({ subdomain }),
    headers: { "Content-Type": "application/json" },
    types: [
      SIGNUPFORM_REQUEST,
      SIGNUPFORM_SUCCESS,
      SIGNUPFORM_FAILURE,
    ],
  });
};

export const createPractitioner = (data, token) => {
  return createAction({
    endpoint: BASE_URL + `/api/v1/user`,
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json", "token": token },
    types: [
      SIGNUPFORM_REQUEST,
      SIGNUPFORM_SUCCESS,
      SIGNUPFORM_FAILURE,
    ],
  });
};

export const logout = () => {
  return { type: LOGOUT }
}

export const changeAuthState = (payload) => {
  return { type: CHANGE_AUTH, payload }
}

