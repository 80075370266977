let calendarEvents = [
    {
        "created_at": "2021-11-08T11:33:12.483269Z",
        "end_time": "2022-01-05T08:15:00.000000Z",
        "event_guests": [],
        "event_memberships": [
            {
                "user": "https://api.calendly.com/users/c676b417-43e5-462f-9abe-65eeebb6a8af"
            }
        ],
        "event_type": "https://api.calendly.com/event_types/a59173e6-8cac-4e6d-bb2f-b8552c03b1d2",
        "invitees_counter": {
            "active": 1,
            "limit": 1,
            "total": 1
        },
        "location": {
            "location": null,
            "type": "custom"
        },
        "name": "15 Minute Meeting",
        "start_time": "2022-01-05T08:00:00.000000Z",
        "status": "active",
        "updated_at": "2021-11-08T11:33:13.304721Z",
        "uri": "https://api.calendly.com/scheduled_events/3e0a939f-5a15-4259-93b6-e832e1fc7941"
    },
    {
        "created_at": "2021-11-13T15:57:55.886897Z",
        "end_time": "2021-11-19T04:15:00.000000Z",
        "event_guests": [],
        "event_memberships": [
            {
                "user": "https://api.calendly.com/users/c676b417-43e5-462f-9abe-65eeebb6a8af"
            }
        ],
        "event_type": "https://api.calendly.com/event_types/a59173e6-8cac-4e6d-bb2f-b8552c03b1d2",
        "invitees_counter": {
            "active": 1,
            "limit": 1,
            "total": 1
        },
        "location": {
            "location": null,
            "type": "custom"
        },
        "name": "15 Minute Meeting",
        "start_time": "2021-11-19T04:00:00.000000Z",
        "status": "active",
        "updated_at": "2021-11-13T15:57:56.665471Z",
        "uri": "https://api.calendly.com/scheduled_events/23c373d7-835a-4d3c-bfd0-7ed01a7063e4"
    },
    {
        "created_at": "2021-11-15T05:34:34.901466Z",
        "end_time": "2021-11-30T04:30:00.000000Z",
        "event_guests": [],
        "event_memberships": [
            {
                "user": "https://api.calendly.com/users/c676b417-43e5-462f-9abe-65eeebb6a8af"
            }
        ],
        "event_type": "https://api.calendly.com/event_types/0a907c2b-9cab-4197-be52-cd0e48b3ece6",
        "invitees_counter": {
            "active": 1,
            "limit": 1,
            "total": 1
        },
        "location": {
            "location": null,
            "type": "custom"
        },
        "name": "60 Minute Meeting",
        "start_time": "2021-11-30T03:30:00.000000Z",
        "status": "active",
        "updated_at": "2021-11-15T05:34:35.720915Z",
        "uri": "https://api.calendly.com/scheduled_events/8949e2dd-f48e-4826-a658-8ff708f4f2e8"
    }
]

export let events = calendarEvents.map((data) => {
    return {
        key: data.name,
        id: data.name,
        title: data.name,
        start: new Date(data.start_time),
        end: new Date(data.end_time)
    }
})