import React from 'react'
import { Card } from 'antd';

function AdminDashboard() {
    return (
        <div style={{ minHeight: '100vh' }}>
            <div className="text-center">
                <h4>Admin Dashboard</h4>
                <div className="row my-3">
                    <div className="col-3">
                        <Card className="br p-2" hoverable style={{ width: 300 }}>
                            <div>Document Setting</div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminDashboard
