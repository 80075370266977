import React, { useState, useEffect } from 'react';
import Header from '../../component/Header';
import { parse, parseUrl } from "query-string";
import _ from 'lodash';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import VfInput from '../../component/utils/VfInput';
import { createPractitioner, searchSubdomain } from '../../store/actions/auth';
import VfCheckBox from '../../component/utils/VfCheckBox';
import VfInputLabel from '../../component/utils/VfInputLabel';
import toast from 'react-hot-toast';

function SignupPage() {
    const history = useHistory();
    const dispatch = useDispatch()
    const [token, settoken] = useState("")
    const [fName, setfName] = useState("");
    const [lName, setlName] = useState("");
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("")
    const [confirmPassword, setconfirmPassword] = useState("");
    const [company, setcompany] = useState("");
    const [mobileno, setmobileno] = useState('');
    const [website, setwebsite] = useState("");
    const [licence, setlicence] = useState("");
    const [termsAgreed, settermsAgreed] = useState(false);
    const [subdomain, setsubdomain] = useState("")

    const [fNameError, setfNameError] = useState("");
    const [lNameError, setlNameError] = useState("");
    const [emailError, setemailError] = useState("");
    const [passwordError, setpasswordError] = useState("")
    const [confirmPasswordError, setconfirmPasswordError] = useState("");
    const [companyError, setcompanyError] = useState("");
    const [mobilenoError, setmobilenoError] = useState('');
    const [websiteError, setwebsiteError] = useState("");
    const [licenceError, setlicenceError] = useState("");
    const [termsAgreedError, settermsAgreedError] = useState(false);
    const [subdomainError, setsubdomainError] = useState("")

    const [sdFixed, setsdFixed] = useState(false)
    const [searchingSD, setsearchingSD] = useState(false)

    useEffect(() => {
        const url = parse(window.location.href, { decode: false });
        console.log(url)
        if (_.isEmpty(url.token) || _.isEmpty(url.email) || _.isEmpty(url.company))
            window.location.href = window.location.origin;
        else {
        setcompany(url.company); setemail(url.email); settoken(url.token)
        }
    }, []);

    const createPractitionerFn = (e) => {
        e.preventDefault();
        setfNameError(""); setlNameError(""); setpasswordError(''); setconfirmPasswordError(""); settermsAgreedError("")
        if (fName === "") setfNameError("First Name is required!");
        if (lName === "") setlNameError('Last name is required!');
        if (password === "") setpasswordError("Password is required!");
        if (confirmPassword === "") setconfirmPasswordError("Confirm Password is required");
        if (password !== confirmPassword) { setpasswordError("Password doesnt match!"); setconfirmPasswordError("Password doesnt match!") }
        if (!termsAgreed) settermsAgreedError("Must Agree the terms of use")
        if (subdomain === "") setsubdomainError("Subdomain is required")
        if (!sdFixed) setsubdomainError("Subdomain is required")
        if (fName !== "" &&
            lName !== "" &&
            password !== "" &&
            password === confirmPassword && termsAgreed
        )
            handlePracCreation()
    }
    const handlePracCreation = async () => {
        const data = {
            fName, lName, email, password, confirmPassword, company, mobileno, website, licence, subdomain
        }
        const response = await dispatch(createPractitioner(data, token));
        if (!response.error) {
            toast.success("Account Created Successfully")
            history.push("/static/login")
        }
    }

    useEffect(() => {
        setsdFixed(false);
        searchSD();
    }, [subdomain])

    useEffect(() => {
        console.log("subdomain fixed")
    }, [sdFixed])

    const searchSD = async () => {
        setsearchingSD(true)
        const respose = await dispatch(searchSubdomain(subdomain));
        console.log("domain response", respose);
        setsearchingSD(false)
        if (!respose.error) setsdFixed(true)
    }

    return (
        <>
            <div className="position-relative" style={{ top: 0 }}>
                <Header />
            </div>
            <div className="container my-5 py-5">
                <div className="alert alert-dark text-primary fw-bold fs-5" >
                    <i className="fa fa-bullhorn fs-2 me-2" /> You must be a licensed Lawyer or Immigration Consultant to register.
                </div>
                <div className="card my-2 p-4 br-lg hoverable">
                    <h3 className="fw-normal">
                        Complete registration process to begin using software
                    </h3>
                    <div>
                        <form className="w-100" onSubmit={createPractitionerFn}>
                            <div className="row justify-content-center my-4">
                                <div className="col-12 col-md-8 col-lg-6 ">
                                    <VfInput
                                        showLabel
                                        icon={<i class="fas fa-id-card"></i>}
                                        required
                                        type="text"
                                        error={fNameError}
                                        value={fName}
                                        inputHandler={(e) => setfName(e.target.value)}
                                        outline size="lg" label="First Name" />
                                    <VfInput
                                        showLabel
                                        required
                                        icon={<i class="fas fa-id-card"></i>}
                                        type="text"
                                        error={lNameError}
                                        value={lName}
                                        inputHandler={(e) => setlName(e.target.value)}
                                        outline size="lg" label="Last Name" />
                                    <VfInput
                                        showLabel
                                        required
                                        icon={<i class="fas fa-envelope"></i>}
                                        isDisable
                                        type="email"
                                        value={email}
                                        inputHandler={(e) => setemail(e.target.value)}
                                        outline size="lg" label="Email" />
                                    <VfInput
                                        showLabel
                                        required
                                        icon={<i class="fas fa-lock"></i>}
                                        type="password"
                                        error={passwordError}
                                        value={password}
                                        inputHandler={(e) => setpassword(e.target.value)}
                                        outline size="lg" label="Password " />
                                    <VfInput
                                        showLabel
                                        required
                                        icon={<i class="fas fa-lock"></i>}
                                        type="password"
                                        error={confirmPasswordError}
                                        value={confirmPassword}
                                        inputHandler={(e) => setconfirmPassword(e.target.value)}
                                        outline size="lg" label="Confirm Password " />
                                </div>
                                <div className="col-12 col-md-8 col-lg-6">
                                    <VfInputLabel
                                        showSuccess={sdFixed}
                                        showLabel
                                        required
                                        icon={<i class="fas fa-globe-asia"></i>}
                                        value={subdomain}
                                        type="text"
                                        error={subdomainError}
                                        inputHandler={(e) => setsubdomain(e.target.value)}
                                        outline size="lg" label="Choose your subdomain"
                                    />
                                    <VfInput
                                        showLabel
                                        required
                                        icon={<i class="fas fa-building"></i>}
                                        value={company}
                                        isDisable
                                        type="text"
                                        inputHandler={(e) => setcompany(e.target.value)}
                                        outline size="lg" label="Company Name " />
                                    <VfInput
                                        showLabel
                                        icon={<i class="fas fa-globe"></i>}
                                        value={website}
                                        type="url"
                                        inputHandler={(e) => setwebsite(e.target.value)}
                                        outline size="lg" label="Website URL (None, if not applicable)" />
                                    <VfInput
                                        showLabel
                                        icon={<i class="fas fa-mobile"></i>}
                                        value={mobileno}
                                        type="number"
                                        inputHandler={(e) => setmobileno(e.target.value)}
                                        outline size="lg" label="Mobile No" />
                                    <VfInput
                                        showLabel
                                        type="text"
                                        icon={<i class="far fa-id-badge"></i>}
                                        value={licence}
                                        inputHandler={(e) => setlicence(e.target.value)}
                                        size="lg" label="License/ Regulation No." />
                                </div>
                            </div>
                            <VfCheckBox
                                error={termsAgreedError}
                                checked={termsAgreed} onCheck={() => settermsAgreed(!termsAgreed)}>
                                Agree to <a href="#">terms of use</a>
                            </VfCheckBox>
                            <div className="text-left">
                                <button className="btn btn-primary br mt-3 w-100" type="submit">
                                    Register
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SignupPage
