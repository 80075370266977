import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSingleCase, getMultipleCases } from '../../../store/actions/case';
import { useHistory } from 'react-router';
import moment from 'moment';
import toast from 'react-hot-toast';


const CaseViewInViewClientPage = ({ ids }) => {
    const h = useHistory();
    const [gettingCase, setgettingCase] = useState(true);
    const [data, setdata] = useState(null)
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const fetchCasesFn = (ids) => dispatch(getMultipleCases(ids, token))
    const deleteCase = (id) => dispatch(deleteSingleCase(id, token));

    useEffect(() => {
        __fetchCasesFn();
    }, [])

    const __fetchCasesFn = async () => {
        const response = await fetchCasesFn(ids);
        setgettingCase(false)
        if (!response.error) {
            console.log('response', response)
            setdata(response.payload?.data);
        }
    }

    const deleteSingleCaseFn = async (id) => {
        const response = await deleteCase(id);
        if (!response.error) {
            toast.success('Case deleted Successfully');
            __fetchCasesFn();
        }

    }

    const columns = [
        {
            title: 'Case Name',
            dataIndex: 'caseType',
            render: (text, value) => <div>{text}</div>,
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            render: (v) => <div>{moment(v).fromNow()}</div>
        },
        {
            title: 'Last Updated',
            dataIndex: 'updatedAt',
            render: (v) => <div>{moment(v).fromNow()}</div>
        },
        {
            title: '',
            dataIndex: '_id',
            render: (text, b, c) => {
                return <div className="text-end">
                    <button className="btn btn-sm btn-danger mx-1" onClick={() => deleteSingleCaseFn(text)}>Delete Case</button>
                    <button className="btn btn-sm btn-primary" onClick={() => h.push('/practitioner/cases/single_case', { id: text })}>View Case</button>
                </div >
            },
        },
    ];

    return <div>
        {gettingCase ? 'Loading....'
            : <ul>
                <Table
                    // bordered
                    columns={columns}
                    pagination={false}
                    dataSource={data}
                />
            </ul>
        }
    </div>
}
export default CaseViewInViewClientPage
