import { useState, useEffect } from 'react'
import LImage from '../../assets/static/login_2.png';
import Logo from '../../assets/logo.png'
import { useDispatch, useSelector } from 'react-redux';
import { authenticate, changeAuthState, searchSubdomain } from '../../store/actions/auth'
import { NavLink, Redirect, useHistory } from 'react-router-dom';
import VfInput from '../../component/utils/VfInput';
import { Spin, Space } from 'antd';
import VfInputLabel from '../../component/utils/VfInputLabel';
import toast from 'react-hot-toast';

export const LoginPage = () => {
    const h = useHistory()
    const dispatch = useDispatch();
    const [searchingSD, setsearchingSD] = useState(true)
    const [inputSD, setinputSD] = useState("")
    const [sdExist, setsdExist] = useState(false)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("")
    const loggingIn = useSelector(state => state.auth.loading)
    const subdomain = useSelector(state => state.auth.currentSD)

    useEffect(() => {
        setsdExist(true);
        setsearchingSD(false);
    // var host = window.location.host.split(".")
    // let subdomain = ""
    // if (host.length === 3) {
    //     subdomain = host[0];
    //     console.log("subdmoain", subdomain)
    //     searchSDFn(subdomain);
    // }
    // else {
    //     setsearchingSD(false)
    //     console.log("no sd")
    // }
}, [])

const searchSDFn = async (sd) => {
    const response = await dispatch(searchSubdomain(sd));
    setsearchingSD(false)
    if (!response.error) {
        if (response.payload.code === 201) {
            dispatch(changeAuthState({ currentSD: sd }))
            setsdExist(true)
        }
        else toast.error("No Such subdmain exist!")
    } else {
        console.log(response, "no doma")
        // toast.error("No Such subdmain exist!")
        setsdExist(false)
    }
}

const logUserIn = async (e) => {
    e.preventDefault()
    const response = await dispatch(authenticate(email, password));
}
const switchToDB = () => {
    if (inputSD.length < 3) return toast.error('Subdomain should be morethan 2 character')
    let l = window.location
    let da = l.host.split(".")
    if (da.length === 2) {
        let url = l.protocol + "//" + inputSD + "." + l.host + l.pathname
        window.open(url, "_self")
    }
    else if (da.length === 3) {
        let url = l.protocol + "//" + inputSD + "." + da[1] + "." + da[2] + l.pathname
        window.open(url, "_self")
    } else toast.error("Invalid URL")
}

return (
    <div style={{ background: `url(${LImage}) no-repeat center center fixed`, backgroundSize: 'cover', width: '100vw', height: '100vh' }}>
        <div>
            <div className="position-absolute h-100 login-card" style={{ width: 500,height:'90vh',left:'0%' }}>
                <div className="position-relative">
                    <div className="position-absolute" style={{ top: 50, left: 20 }}>
                        <NavLink to="/" className="text-light fw-bold text-uppercase" >
                            <i className="fa fa-chevron-left me-2" />Bact to homepage
                        </NavLink>
                    </div>
                </div>
                {
                    searchingSD
                        ? <div
                            className="position-absolute"
                            style={{ top: "50%", left: "50%" }}>

                            <Spin size="large" className="text-primary" /></div>
                        : !sdExist
                            ? <div
                                className="position-absolute w-75"
                                style={{ top: "30%", left: "10%" }}
                            >
                                <div className="text-center text-light">
                                    <img src={Logo} alt="" style={{ width: 160, height: 80 }} /></div>
                                <h4 className="text-light mt-5">
                                    Enter your subdomain
                                </h4>
                                <VfInputLabel
                                    icon={<i class="fas fa-globe-europe"></i>}
                                    inputHandler={(e) => setinputSD(e.target.value)}
                                />
                                <button className="btn btn-light px-5 float-end"
                                    onClick={switchToDB}
                                >
                                    Go
                                </button>
                            </div>
                            : <div className="px-0">
                                <div className=" px-4 py-5 ">
                                    <div className="my-5 py-5"></div>
                                    <div className="text-center text-light">
                                        <img src={Logo} alt="" style={{ width: 160, height: 80 }} />
                                        {/* {sdExist && <>
                                            <span className="fs-2 me-2 fw-bold">|</span>
                                            <div className="d-inline-block fs-2 fw-bold bg-dark gradient p-1 br" style={{ verticalAlign: 'sub', textTransform: 'capitalize' }}>
                                                {subdomain}
                                            </div>
                                        </>} */}
                                    </div>
                                    <form className="my-2" onSubmit={logUserIn} onReset={() => { setEmail(""); setPassword("") }}>

                                        <div className="mt-5">
                                            <VfInput
                                                icon={<i class="fas fa-at"></i>}
                                                showLabel
                                                label='Email'
                                                size='lg'
                                                className="my-2"
                                                outline
                                                required
                                                value={email}
                                                background
                                                color="secondary"
                                                inputHandler={(e) => setEmail(e.target.value)} />
                                            <VfInput
                                                background
                                                showLabel
                                                icon={<i class="fas fa-key"></i>}
                                                required
                                                value={password}
                                                label='Password'
                                                color="secondary"
                                                id='formControlLg'
                                                type='password'
                                                size='lg'
                                                inputHandler={(e) => setPassword(e.target.value)} />
                                        </div>
                                        <div className="text-end">
                                            <button className="px-5 me-2 btn btn-warning br font-weight-bold" color="info" type="reset" >Reset</button>
                                            <button className="px-5 btn btn-secondary br font-weight-bold" color="light" type='submit'>{loggingIn ? "Loading..." : "Login"}</button>
                                        </div>
                                        <div className="text-light mt-2 fw-bold">
                                        New User ? <a href="/#about" className="text-light">Create New Account</a>
                                        </div>
                                    </form>
                                </div>
                            </div>

                }
            </div>
        </div>
    </div>
)
}

// export default LoginPage
