import React from 'react';

const AdminCardSection1 = () => {
  return (
    <div className="row my-5">
      <div className="col-12 col-md-6 col-lg-3 mb-r">
        <div className="cascading-admin-card card">
          <div className="admin-up">
            <i className="far fa-money-bill-alt text-primary" />
            <div className="data">
              <p>Revenue</p>
              <h4>
                <strong>$2000</strong>
              </h4>
            </div>
          </div>
          <div>
            <div className="progress">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar bg-primary" role="progressbar"
                style={{ width: '25%' }}></div>
            </div>
            <div>Better than last week (25%)</div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-r">
        <div className="card cascading-admin-card">
          <div className="admin-up">
            <i className="fas fa-chart-line text-warning" />
            <div className="data">
              <p>Approval Rating</p>
              <h4>
                <strong>200</strong>
              </h4>
            </div>
          </div>
          <div>
            <div className="progress">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar bg grey" role="progressbar"
                style={{ width: '25%' }}></div>
            </div>
            <div>Worse than last week (25%)</div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-r">
        <div className="card cascading-admin-card">
          <div className="admin-up">
            <i className="fas fa-check-double text-dark" />

            <div className="data">
              <p>Approved</p>
              <h4>
                <strong>20000</strong>
              </h4>
            </div>
          </div>
          <div>
            <div className="progress">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar grey darken-2" role="progressbar"
                style={{ width: '75%' }}></div>
            </div>
            <div>Worse than last week (75%)</div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3 mb-r">
        <div className="card cascading-admin-card">
          <div className="admin-up">
            <i className="fas fa-thumbs-down text-danger" />
            <div className="data">
              <p>Denied</p>
              <h4>
                <strong>23</strong>
              </h4>
            </div>
          </div>
          <div>
            <div className="progress">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar bg-primary" role="progressbar"
                style={{ width: '25%' }}></div>
            </div>
            <div>Better than last week (25%)</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminCardSection1;

