import React from 'react'

function CustHomepage() {
    return (
        <div style={{ height: '100vh' }}>
            <div className="text-center">
                <h2>
                    Customer Home Page
                </h2>
            </div>
        </div>
    )
}

export default CustHomepage