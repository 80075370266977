import React from 'react'

function SettingCard({ title, to, icon }) {
    return (
        <div className="d-flex br cp py-5 setting-card">
            <div style={{ fontSize: '40px' }}>{icon}</div>
            <div className="mx-2 fw-bold ">{title}</div>
        </div>
    )
}

export default SettingCard
