import React, { useState, useEffect } from 'react'
import { Avatar, Switch } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import toastr from 'react-hot-toast'

import { updateClientStatus } from '../../../store/actions/ClientAndProspects'
import VfSelect from '../../../component/utils/VfSelect'
import VfDatePicker from '../../../component/utils/VfDatePicker'
import { caseStatusArray, caseTypes } from '../../../assets/input_data.json';
import { getSingleCase, updateSingleCase } from '../../../store/actions/case'
import L_CaseProfilePage from '../../../component/Loading/Case/L_CaseProfilePage'

function CaseProfilePage({ caseId, activeTab }) {
    const l = useLocation();
    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token);
    const updateClientFn = (data) => dispatch(updateClientStatus(data, token));
    const getSingleCaseFn = (id) => dispatch(getSingleCase(id, token));
    const updateSingleCaseFn = (id, data) => dispatch(updateSingleCase(id, token, data));

    const [gettingCaseInfo, setgettingCaseInfo] = useState(true);
    const [changingCaseType, setchangingCaseType] = useState(false);
    const [allowingClientLogin, setallowingClientLogin] = useState(false);
    const [allowingMessaging, setallowingMessaging] = useState(false);

    const [clientData, setclientData] = useState({})
    const [caseData, setcaseData] = useState({})
    const [allowClietLogin, setallowClietLogin] = useState(false);
    const [allowMessaging, setallowMessaging] = useState(false);
    const [actualCaseType, setactualCaseType] = useState('');
    const [caseStatus, setcaseStatus] = useState('');


    useEffect(() => {
        if (activeTab == "1") getCaseInfoFn();
    }, [activeTab])

    useEffect(() => {
        console.log('case-data', caseData)
    }, [caseData])

    const getCaseInfoFn = async () => {
        const response = await getSingleCaseFn(caseId);
        if (!response.error) {
            let responsePld = response.payload.data;
            setclientData(response.payload.data.client)
            console.log('case details:', response.payload)
            setallowMessaging(response.payload.data.client.allowMessaging);
            setallowClietLogin(response.payload.data.client.allowLogin);
            setactualCaseType(response.payload.data.caseType)
            setcaseStatus(response.payload.data.caseStatus);
            delete responsePld.client;
            setcaseData(responsePld);
            setgettingCaseInfo(false)
        }
    }


    const transitProspectToCase = async (e) => {
        console.log('log', e)
        setchangingCaseType(true)
        const response = await updateClientFn({
            clientType: e ? 'client' : 'prospects',
            email: clientData.email,
        });
        setchangingCaseType(false)
        if (!response.error) {
            setclientData(ud => {
                let _ud = { ...ud };
                _ud.clientType = e ? 'client' : 'prospects';
                return _ud;
            })
            toastr.success("Client type changed");
        }
    }

    const allowClietLoginFn = async (e) => {
        setallowingClientLogin(true);
        const response = await updateClientFn({
            allowLogin: e,
            email: clientData.email,
        });
        setallowingClientLogin(false);
        if (!response.error) {
            setallowClietLogin(!allowClietLogin);
        }
    }

    const allowClietMessaginFn = async (e) => {
        setallowingMessaging(true);
        const response = await updateClientFn({
            allowMessaging: e,
            email: clientData.email,
        });
        setallowingMessaging(false);
        if (!response.error) {
            setallowMessaging(!allowMessaging);
        }
    }

    const changeCaseType = async (e) => {
        const response = await updateSingleCaseFn(caseData._id, { caseType: e });
        if (!response.error) {
            setactualCaseType(e);
        }
    }

    const changeCaseStatus = async (e) => {
        const response = await updateSingleCaseFn(caseData._id, { caseStatus: e });
        if (!response.error) {
            setcaseStatus(e);
        }
    }

    useEffect(() => {

    }, [allowMessaging])
    return (
        <div>
            {gettingCaseInfo
                ? <L_CaseProfilePage />
                : <div className="container-xxl">
                    <div className="row my-5">
                        <div className="col-12 col-md-6">
                            <h4>
                                Client Profile
                            </h4>
                            <div className="card shadow-sm p-2 br py-4">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="my-3 text-center">
                                            <Avatar
                                                src="https://joeschmoe.io/api/v1/jon"
                                                size={180}
                                            />,
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 my-auto">
                                        <h6><b>Name :</b> {clientData.fName} {clientData.lName}</h6>
                                        <h6><b>Status :</b> {clientData.status}</h6>
                                        <h6 className="text-capitalize"><b>Case Type :</b> {clientData.clientType}</h6>
                                        <h6><b>Created At :</b> {moment(clientData.createdAt).fromNow()}</h6>
                                        <h6><b>Updated At :</b> {moment(clientData.updatedAt).fromNow()}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <h4>Client Actions</h4>
                            <div className="card p-2 shadow-sm br hoverable my-2">
                                <div className="d-flex flex-column py-3">
                                    <div className="d-flex justify-content-between my-4">
                                        <b>Convert Client Type</b> <Switch
                                            loading={changingCaseType}
                                            checked={clientData.clientType === "client"}
                                            unCheckedChildren="Prospect" checkedChildren="Client" onChange={transitProspectToCase} />
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <b>Allow Client Login </b> <Switch
                                            loading={allowingClientLogin}
                                            checked={allowClietLogin}
                                            unCheckedChildren="Not Allowed" checkedChildren="Allowed" onChange={allowClietLoginFn} />
                                    </div>
                                    <div className="d-flex justify-content-between my-4">
                                        <b>Messaging</b> <Switch
                                            loading={allowingMessaging}
                                            checked={allowMessaging}
                                            unCheckedChildren="Off" checkedChildren="On" onChange={allowClietMessaginFn} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <h4>Case Actions</h4>
                            <div className="card p-2 shadow-sm br hoverable my-2">
                                <div className="d-flex flex-column ">
                                    <div className="d-flex justify-content-between my-2">
                                        <div className="my-auto">
                                            Case Type
                                        </div>
                                        <div className="w-50">
                                            <VfSelect
                                                value={actualCaseType}
                                                selectOptions={caseTypes}
                                                onChange={changeCaseType}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between my-2">
                                        <div className="my-auto">
                                            Case Status
                                        </div>
                                        <div className="w-50">
                                            <VfSelect
                                                value={caseStatus}
                                                onChange={changeCaseStatus}
                                                selectOptions={caseStatusArray} />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between my-2">
                                        <div className="my-auto">
                                            Application Submitted Date
                                        </div>
                                        <div className="w-50"><VfDatePicker /></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* client details */}
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-11 col-md-6 col-lg-3">
                                <div className="card p-3 shadow-sm br my-2">
                                    <h4>Communicate :</h4>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Assing Team Members :
                                        </div>
                                        <VfSelect />
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold my-2">
                                            Whatsapp :
                                        </div>
                                        <button className="btn btn-sm btn-primary" onClick={() => window.open(`https://wa.me/${clientData.phone}`)}>Send Message</button>
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold my-2">
                                            Email :
                                        </div>
                                        <button className="btn btn-sm btn-primary" onClick={() => window.open(`mailto:${clientData.email}`)}>Send Email</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-11 col-md-6 col-lg-3">
                                <div className="card br p-3 shadow-sm my-2">
                                    <h4>Personal Detail:</h4>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            First  Name :
                                        </div>
                                        {clientData.fName}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Last Name :
                                        </div>
                                        {clientData.lName}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            DOB :
                                        </div> {clientData.dob}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Maritial Status:
                                        </div>
                                        {clientData.maritialStatus}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Country of Residence:
                                        </div>
                                        {clientData.cofr}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Country of Citizenship:
                                        </div>
                                        {clientData.cofc}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Date of birth:
                                        </div>
                                        {clientData.dob}
                                    </div>
                                </div>
                            </div>
                            <div className="col-11 col-md-6 col-lg-3">
                                <div className="card br  p-3 shadow-sm my-2">
                                    <h4>Contact Detail:</h4>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Primary Email:
                                        </div>
                                        {clientData.email}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Secondary Email:
                                        </div>
                                        {clientData.semail ? clientData.semail : '--'}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Primary phone:
                                        </div>
                                        {clientData.phone}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Secondary phone:
                                        </div>
                                        {clientData.sphone ? clientData.sphone : '--'}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Contact Address:
                                        </div>
                                        {clientData.address}
                                    </div>
                                </div>
                            </div>
                            <div className="col-11 col-md-6 col-lg-3">
                                <div className="card br shadow-sm p-3">
                                    <h4>Family Detail:</h4>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            First Name:
                                        </div>
                                        {clientData.ffName ? clientData.ffName : '--'}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Last Name:
                                        </div>
                                        {clientData.flName ? clientData.flName : '--'}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Email:
                                        </div>
                                        {clientData.femail ? clientData.femail : '--'}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Phone:
                                        </div>
                                        {clientData.fphone ? clientData.fphone : '--'}
                                    </div>

                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Country of Residence:
                                        </div>
                                        {clientData.fcofr ? clientData.fcofr : '--'}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Country of Citizenship:
                                        </div>
                                        {clientData.fcofc ? clientData.fcofc : '--'}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Has Children:
                                        </div>
                                        {clientData.hasChildren ? "Yes" : 'No'}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Number of Children:
                                        </div>
                                        {clientData.nofc ? clientData.nofc : '--'}
                                    </div>
                                    <div className="ms-2 mt-2">
                                        <div className="fw-bold">
                                            Children Detail:
                                        </div>
                                        {clientData.cdetails ? clientData.cdetails : '--'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default CaseProfilePage
