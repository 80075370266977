import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import authReducer from "./auth";
import uiReducer from "./uiReducers";

const reducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  ui: uiReducer,
});

export default reducer;
