import React from 'react'
import ContentLoader from 'react-content-loader'

function L_CaseProfilePage() {
    return (
        <ContentLoader viewBox="0 0 480 350">
            {/* Only SVG shapes */}
            <circle cx="75" cy="50" r="50" />

            <rect x="150" y="10" rx="4" ry="4" width="80" height="20" />
            <rect x="150" y="35" rx="3" ry="3" width="80" height="20" />
            <rect x="150" y="60" rx="3" ry="3" width="80" height="20" />
            <rect x="150" y="85" rx="3" ry="3" width="80" height="20" />

            <rect x="250" y="10" rx="3" ry="3" width="100" height="100" />
            <rect x="360" y="10" rx="3" ry="3" width="100" height="100" />

            <rect x="30" y="140" rx="3" ry="3" width="100" height="200" />
            <rect x="140" y="140" rx="3" ry="3" width="100" height="200" />
            <rect x="250" y="140" rx="3" ry="3" width="100" height="200" />
            <rect x="360" y="140" rx="3" ry="3" width="100" height="200" />

            
        </ContentLoader>
    )
}

export default L_CaseProfilePage
