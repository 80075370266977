import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, Radio } from 'antd';
import { getApplicant } from '../../../store/actions/singlecase';

const { TabPane } = Tabs;

function CaseDocumentPage({ activeTab, caseId }) {
    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token);
    const getApplicantAction = (id) => dispatch(getApplicant(id, token))
    const [applicants, setApplicants] = useState([])
    const [currentApplicant, setcurrentApplicant] = useState(null)

    useEffect(() => {
        if (activeTab === "3") __getApplicant(caseId)
    }, [activeTab])

    useEffect(() => {
        console.log('applicant data updated', applicants)
    }, [applicants])

    const __getApplicant = async (id) => {
        const response = await getApplicantAction(id)
        console.log(response.payload)
        if (!response.error) setApplicants(response.payload.data);
    }
    return (
        <div>
            <div className="d-flex justify-content-end">
                {/* <h5>Case Questionnaire page</h5> */}
                <button className="btn btn-primary br"> + Add Applicant</button>
            </div>
            <div>
                <Tabs tabPosition="left" type="card">
                    {applicants.map(_applicant => {
                        return <TabPane
                            tab={_applicant.name}
                            key="1"
                        >
                            <div className="text-center my-3">
                                {_applicant.questionnaireAssigned
                                    ? "Document Assignment Coming soon.."
                                    : "Document Not Assigned"}
                            </div>
                        </TabPane>

                    })}
                </Tabs>
            </div>
        </div>
    )
}

export default CaseDocumentPage
