import ContentLoader from 'react-content-loader'
function L_ClientPage() {
    return (
        <ContentLoader viewBox="0 0 480 350">
        {/* Only SVG shapes */}
        <rect x="0" y="10" rx="3" ry="3" width="150" height="150" />
        <rect x="160" y="10" rx="3" ry="3" width="150" height="150" />
        <rect x="320" y="10" rx="3" ry="3" width="150" height="150" />

        <rect x="0" y="170" rx="3" ry="3" width="150" height="150" />
        <rect x="160" y="170" rx="3" ry="3" width="150" height="150" />
        <rect x="320" y="170" rx="3" ry="3" width="150" height="150" />
    </ContentLoader>
    )
}

export default L_ClientPage
