import React, { useState, useEffect } from 'react'
import { Switch } from "react-router-dom";
import { useSelector } from 'react-redux';

// practitioner
import CalenderPage from '../pages/practitioner/CalenderPage';
import CasesPage from '../pages/practitioner/CasesPage';
import ClientAndProspectPage from '../pages/practitioner/ClientAndProspectPage';
import ContactsPage from '../pages/practitioner/ContactsPage';
import EmailPage from '../pages/practitioner/EmailPage';
import PracAccountPage from '../pages/practitioner/PracAccountPage';
import PracDashboard from '../pages/practitioner/PracDashboard';
import PracHomePage from '../pages/practitioner/PracHomePage';
import PracSettingsPage from '../pages/practitioner/PracSettingsPage';
import ReportingPage from '../pages/practitioner/ReportingPage';
import TasksPage from '../pages/practitioner/TasksPage';

// static pages
import { LoginPage } from '../pages/static/LoginPage';
import { Homepage } from '../pages/static/Homepage';
import SignupPage from '../pages/static/SignupPage';

// routes
import { HomepageRoute } from './HomepageRoute';
import { PractitionerRoute } from './PractitionerRoute';
import { AdminRoute } from './AdminRoute';
import { LoginRoute } from './LoginRoute';
import { CustomerRoute } from './CustomerRoute';

// layout
import LoginLayout from '../Layout/LoginLayout';
import AdminLayout from '../Layout/AdminLayout';
import CustomerLayout from '../Layout/CustomerLayout';
import HomePageLayout from '../Layout/HomePageLayout';
import MainLayout from '../Layout/MainLayout';


import AdminDashboard from '../pages/admin/AdminDashboard';
import CustHomepage from '../pages/customer/CustHomepage';
import { ResetPasswordPage } from '../pages/static/ResetPasswordPage';
import AddNewClient from '../component/Practitioner/Client/AddNewClient';
import CreateNewCase from '../pages/practitioner/Case/CreateNewCase';
import ViewClientPage from '../pages/practitioner/Client/ViewClientPage';
import SingleCaseHomepage from '../pages/practitioner/Case/SingleCaseHomepage';
import CaseCreationSuccessPage from '../pages/practitioner/Case/CaseCreationSuccessPage';
import CCasesPage from '../pages/customer/CCasesPage';
import CDashboard from '../pages/customer/CDashboard';
import CAccountPage from '../pages/customer/CAccountPage';

export const MainRouter = () => {
    const isAuth = useSelector(state => state.auth.isAuth)
    const userType = useSelector(state => state.auth.userType)

    useEffect(() => {
        console.log("isAuth:", isAuth)
        console.log("userTYpe:", userType)
        console.log("in route page")
    }, [isAuth, userType])

    return (
        <>
            <Switch>
                <HomepageRoute
                    path="/static/features"
                    component={Homepage}
                    layout={HomePageLayout}
                />
                <LoginRoute
                    path="/static/login"
                    component={LoginPage}
                    layout={LoginLayout}
                    isAuth={isAuth}
                    userType={userType}
                />
                <LoginRoute
                    path="/static/reset_password"
                    component={ResetPasswordPage}
                    layout={LoginLayout}
                    isAuth={isAuth}
                    userType={userType}
                />
                <HomepageRoute
                    path="/static/signup"
                    component={Homepage}
                    layout={SignupPage}
                />
                <HomepageRoute
                    path="/static/pricing"
                    component={Homepage}
                    layout={HomePageLayout}
                />
                <PractitionerRoute
                    path="/practitioner/dashboard"
                    layout={MainLayout}
                    component={PracDashboard}
                    isAuth={isAuth}
                    userType={userType}
                />
                {/* <PractitionerRoute
                    path="/practitioner/cases/new"
                    layout={MainLayout}
                    component={CreateNewCase}
                    isAuth={isAuth}
                    userType={userType}
                /> */}
                <PractitionerRoute
                    path="/practitioner/cases/success"
                    layout={MainLayout}
                    component={CaseCreationSuccessPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner/cases/single_case"
                    layout={MainLayout}
                    component={SingleCaseHomepage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner/cases"
                    layout={MainLayout}
                    component={CasesPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner/client_and_prospects/add_cases"
                    layout={MainLayout}
                    component={AddNewClient}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner/client_and_prospects/view"
                    layout={MainLayout}
                    component={ViewClientPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner/client_and_prospects"
                    layout={MainLayout}
                    component={ClientAndProspectPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner/emails"
                    layout={MainLayout}
                    component={EmailPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner/tasks"
                    layout={MainLayout}
                    component={TasksPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner/calender"
                    layout={MainLayout}
                    component={CalenderPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner/contacts"
                    layout={MainLayout}
                    component={ContactsPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner/reporting"
                    layout={MainLayout}
                    component={ReportingPage}
                    isAuth={isAuth}
                    userType={userType}
                /><PractitionerRoute
                    path="/practitioner/settings"
                    layout={MainLayout}
                    component={PracSettingsPage}
                    isAuth={isAuth}
                    userType={userType}
                /><PractitionerRoute
                    path="/practitioner/account"
                    layout={MainLayout}
                    component={PracAccountPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <PractitionerRoute
                    path="/practitioner"
                    layout={MainLayout}
                    component={PracHomePage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <AdminRoute
                    path="/admin/dashboard"
                    layout={AdminLayout}
                    component={AdminDashboard}
                    isAuth={isAuth}
                    userType={userType}
                />
                <CustomerRoute
                    path="/customer/dashboard"
                    layout={CustomerLayout}
                    component={CDashboard}
                    isAuth={isAuth}
                    userType={userType}
                />
                <CustomerRoute
                    path="/customer/cases"
                    layout={CustomerLayout}
                    component={CCasesPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <CustomerRoute
                    path="/customer/account"
                    layout={CustomerLayout}
                    component={CAccountPage}
                    isAuth={isAuth}
                    userType={userType}
                />
                <HomepageRoute
                    path="/"
                    component={Homepage}
                    layout={HomePageLayout}
                />
            </Switch>
        </>
    )
}

