import React from 'react'

function ReportingPage() {
    return (
        <div>
            <div style={{ minHeight: '100vh' }}>
                Reporting Page
            </div>
        </div>
    )
}

export default ReportingPage
