import React, { useState } from 'react'
import { Table } from 'antd';
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router';

import '../../../styles/tablestyle.css'
import CaseTableItem from './CaseTableItem'
import VfSearchInput from '../../utils/VfSearchInput'
import VfCheckBoxOnly from '../../utils/VfCheckBoxOnly';
import { getCases } from '../../../store/actions/case'
import L_CasePage from '../../Loading/Case/L_CasePage';
import CreateNewCaseModal from './CreateNewCaseModal';

function CaseTable({ }) {
    const h = useHistory();
    const [showNewCaseModel, setshowNewCaseModel] = useState(false)
    const token = useSelector(state => state.auth.token);

    const { isLoading, data, refetch } = useQuery('clientList', () => getCases('', token));

    const [selectedRowKeys, setselectedRowKeys] = useState([])
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, value) => <div>{text}
                <div className="badge bg-secondary mx-1">
                    {value.caseType}
                </div>
            </div>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Client Status',
            dataIndex: 'status',
        },
        {
            title: 'Case Status',
            dataIndex: 'caseStatus',
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            render: (v) => <div>{moment(v).fromNow()}</div>
        },
        {
            title: '',
            dataIndex: 'id',
            render: (text, b, c) => {
                // console.log('selected row', text, b, c);
                return <div className="text-end"><button className="btn btn-sm btn-primary" onClick={() => h.push('/practitioner/cases/single_case', { id: text })}>View Case</button></div>
            },
        },
    ];

    return (
        <div className="" style={{minHeight:'80vh'}}>
            {/* <table className="vf-table case-table"> */}
            <div className="row my-3">
                <div className="col-3 my-auto">
                    {/* <div>
                        <ion-icon name="funnel-outline"></ion-icon> Filter
                    </div> */}
                </div>
                <div className="col-7 my-auto">
                    {/* <VfSearchInput /> */}
                </div>
                <div className="col-2 my-auto">
                    {
                        showNewCaseModel && <CreateNewCaseModal fromCAP={false} handleClose={() => setshowNewCaseModel(false)} show={showNewCaseModel} />
                    }
                    <button className="btn btn-primary w-100" onClick={() => setshowNewCaseModel(true)}>+ Add Case</button>
                </div>
            </div>
            {/* <div className="row justify-content-end px-2">
                <div className="col text-end">
                    <button className="btn btn-sm btn-secondary mx-1" disabled={selectedRowKeys.length !== 1}>Add Notes</button>
                    <button className="btn btn-sm btn-secondary mx-1" disabled={selectedRowKeys.length < 1} >Archive</button>
                    <button className="btn btn-sm btn-secondary" disabled={selectedRowKeys.length < 1}>Delete</button>
                </div>
            </div> */}

            {isLoading
                ? <div>
                    <L_CasePage />
                </div>
                : <div className="">
                    <div className="my-2">

                        <Table
                            // fixed="top"
                            rowSelection={{
                                type: 'checkbox',
                                onChange: (selectedRowKeys, selectedRows) => {
                                    setselectedRowKeys(selectedRows)
                                    console.log('selected row', selectedRowKeys, selectedRows)
                                },
                            }}
                            sticky
                            // bordered
                            columns={columns}
                            pagination={{ position: ['bottomCenter'] }}
                            dataSource={data?.data?.data}
                        />
                    </div>

                </div>}
        </div>
    )
}

export default CaseTable
