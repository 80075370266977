import React from 'react'
import { useSelector } from 'react-redux'

function CAccountPage() {
    const user = useSelector(state => state.auth)
    return (
        <div>
            <h4>
                Customer Account Page
            </h4>
            {JSON.stringify(user,null,3)}
        </div>
    )
}

export default CAccountPage
