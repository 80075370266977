import React, { useState } from 'react'
import { Modal, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createApplicant } from '../../../store/actions/singlecase';
import VfInput from '../../utils/VfInput'
import VfSelect from '../../utils/VfSelect';
import toast from 'react-hot-toast';

function AddApplicantModel({ show, onCancel, caseId, cid }) {
    const dispatch = useDispatch();
    const [name, setName] = useState("")
    const [caseID, setcaseID] = useState("")
    const [clientID, setclientID] = useState("");
    const [relationship, setrelationship] = useState("Primary Applicant");
    const [loading, setloading] = useState(false);
    const token = useSelector(state => state.auth.token);
    const createApplicantFn = (data) => dispatch(createApplicant(data, token));
    const __createApplicantFn = async () => {
        if (name.length === "0") toast.error('Name is required')
        let data = {
            client: cid,
            caseID: caseId,
            name,
            relationship
        }
        setloading(true)
        const response = await createApplicantFn(data);
        setloading(false)
        if (!response.error) {
            toast.success('Applicant Created Successfully!')
            onCancel()
        }
    }
    return (
        <Modal
            visible={show}
            onCancel={onCancel}
            footer={[
                <button className="btn btn-secondary px-5" onClick={onCancel}>Cancel</button>,
                <button className="btn btn-primary px-5 mx-1" onClick={__createApplicantFn}>{loading ? <Spin className="text-light px-1" indicator={<LoadingOutlined />} /> : <span className="text-light">Create</span>}</button>
            ]}
        >
            <h4>
                <b>Create New Applicant</b>
            </h4>
            <div className="my-4">
                <form onSubmit={__createApplicantFn}>
                    <VfInput
                        showLabel
                        required
                        label="Full Name"
                        placeholder="Enter Applicant Full name"
                        value={name}
                        inputHandler={e => setName(e.target.value)}
                    />
                    <VfSelect
                        selectOptions={['Primary Applicant', 'Spouse', 'Son', 'Daughter', 'Common Law Partner', 'Father', 'Mother', 'Sponsore', 'Brother', 'Sister', 'Grand Mother', 'Grand Father', 'Grand Son', 'Grand Daughter', 'Nephew', 'Neice', 'Uncle', 'Aunt']}
                        value={relationship}
                        onChange={(e) => setrelationship(e)}
                    />
                </form>
            </div>
        </Modal>
    )
}

export default AddApplicantModel
