import React from 'react'

function LoginLayout({ children }) {
    return (
        <div>
            <div>
                <div className="">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default LoginLayout
