import React from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { events } from "./events";
import * as dates from "./dates";
import "react-big-calendar/lib/css/react-big-calendar.css";

let allViews = Object.keys(Views).map((k) => Views[k]);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: "lightblue",
    },
  });

const localizer = momentLocalizer(moment);

let Schedular = () => (
  <Calendar
    events={events}
    views={allViews}
    step={60}
    showMultiDayTimes
    max={dates.add(dates.endOf(new Date(), "day"), -1, "hours")}
    defaultDate={new Date()}
    components={{
      timeSlotWrapper: ColoredDateCellWrapper,
    }}
    localizer={localizer}
    style={{ height: 500, width: "100%" }}
  />
);

export default Schedular;
