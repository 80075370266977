import { Modal, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router';
import { DatePicker, Space } from 'antd';

import VfInput from '../../utils/VfInput'
import VfSelect from '../../utils/VfSelect'
import { countries } from '../../../assets/input_data.json'
import VfDatePicker from '../../utils/VfDatePicker';
import VfCheckBox from '../../utils/VfCheckBox';
import { createClient, updateClient } from '../../../store/actions/ClientAndProspects';
import { getClient } from '../../../store/actions/ClientAndProspects';
import L_EditCase from '../../Loading/Practitioner/L_EditCase';
import moment from 'moment';

function AddNewClient({ isModalVisible, handleOk, handleCancel, refreshCasePage }) {
    const dispatch = useDispatch();
    const h = useHistory();
    const l = useLocation();
    const maritialStatusOptions = ["Annulled Marriage", "Common Law", "Divorced", "Legally Separated", "Married", "Never Married/Single", "Widowed"];

    const [title, settitle] = useState("")
    const [fName, setfName] = useState("");
    const [lName, setlName] = useState("");
    const [maritialStatus, setMaritialStatus] = useState("")
    const [cofr, setcofr] = useState("")
    const [cofc, setcofc] = useState("")
    const [dob, setdob] = useState(moment())
    const [address, setaddress] = useState("")
    const [email, setemail] = useState("")
    const [semail, setsemail] = useState("")
    const [phone, setphone] = useState("")
    const [sphone, setsphone] = useState("")
    const [ffName, setffName] = useState("")
    const [flName, setflName] = useState("")
    const [fcofr, setfcofr] = useState("")
    const [fcofc, setfcofc] = useState("")
    const [femail, setfemail] = useState("")
    const [fphone, setfphone] = useState("")
    const [hasChildren, sethasChildren] = useState(false)
    const [nofc, setnofc] = useState("");
    const [cdetails, setcdetails] = useState("");

    const currentUserID = useSelector(state => state.auth.currentUserID)
    const token = useSelector(state => state.auth.token);

    const [isEditable, setisEditable] = useState(false);
    const [gettingClient, setgettingClient] = useState(false)

    const createClientFn = async (e) => {
        e.preventDefault();
        if (!dob) return toast.error("DOB is required");
        let data = { title, fName, lName, maritialStatus, cofc, cofr, dob: dob._d.toLocaleDateString(), address, email, semail, phone, sphone, ffName, flName, fcofc, fcofr, femail, fphone, hasChildren, nofc, cdetails }
        data.practitioner = currentUserID
        const response = await dispatch(createClient(data, token))
        if (!response.error) {
            toast.success("Client Creation Successful")
            h.goBack();
        }
    }

    useEffect(() => {
        if (l.state?.editable) {
            setisEditable(true);
            getClientFn(l.state.client_id)
        }
    }, [])

    const getClientFn = async (c_id) => {
        setgettingClient(true);
        const response = await dispatch(getClient(c_id, token));
        setgettingClient(false);
        if (!response.error) {
            const d = response.payload;
            // profile data and contact data
            setfName(d.fName); setlName(d.lName); settitle(d.title); setMaritialStatus(d.maritialStatus); setcofr(d.cofr); setcofc(d.cofc);
            setdob(moment(d.dob));
            console.log()
            setaddress(d.address); setemail(d.email); setsemail(d.semail); setphone(d.phone); setsphone(d.sphone);
            // other info
            setffName(d.ffName); setflName(d.flName); setfcofr(d.fcofr); setfcofc(d.fcofc); setfemail(d.femail); setfphone(d.fphone);
            sethasChildren(d.hasChildren); setnofc(d.nofc); setcdetails(d.cdetails);
        }
    }

    const updateClientFn = async (e) => {
        e.preventDefault();
        // return console.log(dob)
        if (!dob) return toast.error("DOB is required");
        let data = { title, fName, lName, maritialStatus, cofc, cofr, dob: dob._d ? dob._d.toLocaleDateString() : dob, address, email, semail, phone, sphone, ffName, flName, fcofc, fcofr, femail, fphone, hasChildren, nofc, cdetails }
        const response = await dispatch(updateClient(data, token))
        if (!response.error) {
            toast.success("Client Updation Successful")
            h.goBack();
        }
    }

    return (
        <div>
            <div className="container">
                <div className="my-2 mb-4 fw-bold text-primary cp" onClick={() => h.go(-1)}>
                    <ion-icon name="arrow-back-outline"></ion-icon> <span style={{ verticalAlign: 'text-bottom' }}>Client And Prospects</span>
                </div>
                {
                    gettingClient
                        ? <L_EditCase />
                        : <div >
                            <b className="p-2 bg-info br px-4 fs-4">Profile Detail</b>
                            <div className="row justify-content-evenly my-4">
                                <div className="col-5">
                                    <b>Basic Information</b>
                                    <hr />
                                    <VfInput
                                        label="Title"
                                        name="title"
                                        placeholder="Enter title as Mr,Mrs,Ms etc"
                                        showLabel
                                        required
                                        value={title}
                                        inputHandler={(e) => settitle(e.target.value)}
                                    />
                                    <VfInput
                                        label="First Name"
                                        placeholder="Enter First Name"
                                        showLabel
                                        required
                                        value={fName}
                                        inputHandler={(e) => setfName(e.target.value)}
                                    />
                                    <VfInput
                                        label="Last Name"
                                        placeholder="Enter Last Name"
                                        showLabel
                                        required
                                        value={lName}
                                        inputHandler={(e) => setlName(e.target.value)}
                                    />
                                    <VfSelect
                                        label="Maritial Status"
                                        placeholder="Select Marriage status"
                                        showLabel
                                        name="maritialStatus"
                                        required
                                        selectOptions={maritialStatusOptions}
                                        value={maritialStatus}
                                        onChange={(e) => setMaritialStatus(e)}
                                    />
                                    <VfSelect
                                        label="Country of Residence"
                                        placeholder="Select Country"
                                        showLabel
                                        name="cofr"
                                        // ref={register}
                                        required
                                        value={cofr}
                                        onChange={(e) => setcofr(e)}
                                        selectOptions={countries}
                                    />
                                    <VfSelect
                                        label="Country of Citizenship"
                                        placeholder="Select Country"
                                        showLabel
                                        name="cofc"
                                        value={cofc}
                                        required
                                        onChange={(e) => setcofc(e)}
                                        selectOptions={countries}
                                    />
                                    <VfDatePicker
                                        label="Date of birth"
                                        placeholder="Select DOB"
                                        showLabel
                                        name="dob"
                                        value={dob}
                                        onChange={(e) => setdob(e)}
                                        required
                                    />
                                </div>
                                <div className="col-5">
                                    <b>Contact Information</b>
                                    <hr />
                                    <VfInput
                                        label="Address"
                                        showLabel
                                        type="textarea"
                                        value={address}
                                        inputHandler={(e) => setaddress(e.target.value)}
                                    />
                                    <VfInput
                                        label="Primary email"
                                        showLabel
                                        value={email}
                                        isDisable={isEditable}
                                        inputHandler={(e) => setemail(e.target.value)}
                                    />
                                    <VfInput
                                        label="Secondary email"
                                        showLabel
                                        value={semail}
                                        inputHandler={(e) => setsemail(e.target.value)}
                                    />
                                    <VfInput
                                        label="Primary Phone"
                                        showLabel
                                        type="number"
                                        isDisable={phone && isEditable}
                                        value={phone}
                                        inputHandler={(e) => setphone(e.target.value)}
                                    // required
                                    />
                                    <VfInput
                                        label="Secondary Phone"
                                        showLabel
                                        type="number"
                                        value={sphone}
                                        inputHandler={(e) => setsphone(e.target.value)}
                                    // required
                                    />
                                </div>
                            </div>
                            <b className="p-2 bg-info br px-4 fs-4 ">Family Detail</b>
                            <div className="row justify-content-evenly mt-3">
                                <div className="col-5">
                                    <b>Partner Information</b>
                                    <hr />
                                    <VfInput
                                        label="First Name"
                                        showLabel
                                        required
                                        value={ffName}
                                        inputHandler={(e) => setffName(e.target.value)}
                                    />
                                    <VfInput
                                        label="Last Name"
                                        showLabel
                                        required
                                        value={flName}
                                        inputHandler={(e) => setflName(e.target.value)}
                                    />
                                    <VfSelect
                                        label="Country of Residence"
                                        placeholder="Select Country"
                                        showLabel
                                        required
                                        value={fcofr}
                                        onChange={(e) => setfcofr(e)}
                                        selectOptions={countries}
                                    />
                                    <VfSelect
                                        label="Country of Citizenship"
                                        placeholder="Select Country"
                                        showLabel
                                        required
                                        value={fcofc}
                                        onChange={(e) => setfcofc(e)}
                                        selectOptions={countries}
                                    />
                                    <VfInput
                                        label="Email"
                                        showLabel
                                        type="email"
                                        value={femail}
                                        inputHandler={(e) => setfemail(e.target.value)}
                                    // required
                                    />
                                    <VfInput
                                        label="Phone"
                                        showLabel
                                        type="number"
                                        value={fphone}
                                        inputHandler={(e) => setfphone(e.target.value)}
                                    // required
                                    />
                                </div>
                                <div className="col-5">
                                    <b>Other Information</b>
                                    <hr />
                                    <VfCheckBox
                                        checked={hasChildren}
                                        onCheck={() => sethasChildren(!hasChildren)}
                                    >
                                        Has Children
                                    </VfCheckBox>
                                    <VfSelect
                                        label="Number of children"
                                        showLabel
                                        type="number"
                                        selectOptions={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10+"]}
                                        value={nofc}
                                        onChange={(e) => setnofc(e)}
                                    />
                                    <VfInput
                                        label="Children Details"
                                        showLabel
                                        type="textarea"
                                        value={cdetails}
                                        inputHandler={(e) => setcdetails(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="text-end">
                                <button className="btn btn-secondary mx-2 px-5" onClick={() => h.go(-1)} >
                                    Cancel
                                </button>
                                <button className="btn btn-primary px-5" onClick={isEditable ? updateClientFn : createClientFn}>
                                    {isEditable ? "Update" : "Create"}
                                </button>,
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default AddNewClient
