import React from 'react'

function VfCheckBox({ name, label, onCheck, checked, id, children, error }) {
    return (
        <div>
            <label class="pure-material-checkbox">
                <input
                    type="checkbox"
                    name={name}
                    id={id}
                    onChange={onCheck}
                    checked={checked} />
                <span>{children}</span>
            </label>
            <div
                className="text-danger mx-2 my-1 transition-all"
                style={error ? { opacity: 1 } : { opacity: 0 }}
            >
                <i class="fas fa-exclamation-triangle me-2" ></i> {error}
            </div>
        </div>
    )
}

export default VfCheckBox
