import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import Happy from '../../../assets/static/happy.svg'
import CreateNewCaseModal from '../../../component/Practitioner/Case/CreateNewCaseModal';

function CaseCreationSuccessPage() {
    const l = useLocation();
    const h = useHistory();
    const [state, setstate] = useState(l.state)
    const [showNewCaseModel, setshowNewCaseModel] = useState(false)
    console.log('data in case succecss page', l)
    return (
        <div className="position-relative" style={{ height: 1000 }}>
            {
                showNewCaseModel && <CreateNewCaseModal fromCAP={false} handleClose={() => setshowNewCaseModel(false)} show={showNewCaseModel} />
            }
            <div className="text-center">
                <h4 className="text-success"><ion-icon name="checkmark-done-outline"></ion-icon> Case Created Successfully</h4>
                <img src={Happy} alt="" />
            </div>
            <div className="position-absolute" style={{ top: 50, left: 100 }}>
                <h4>
                    Your record has been saved successfully.
                </h4>
                <b>Have a great and productive day!</b>

                <h3 className="mt-5 text-primary">Next Steps</h3>

                <div className="row g-2">
                    <div className="col-11 col-md-8 col-lg-6">
                        <div class="alert alert-secondary " role="alert" style={{ background: 'rgba(209,210,211,0.8)' }}>
                            <h6>
                                <ion-icon name="checkmark-done-outline"></ion-icon> View details
                            </h6>
                            Use this option to view more details on the case you just created. You can then invite your client to complete an assessment, send emails/messages, upload documents, send invoice, complete forms and more.
                            <div className="text-end mt-3">
                                <button className="btn btn-secondary btn-sm" onClick={() => h.replace('/practitioner/cases/single_case', { id: state._id, currentPage: 'profile' })}>View Records</button>
                            </div>
                        </div>
                    </div><div className="col-11 col-md-8 col-lg-6">
                        <div class="alert alert-secondary" role="alert" style={{ background: 'rgba(209,210,211,0.8)' }}>
                            <h6>
                                <ion-icon name="checkmark-done-outline"></ion-icon> Assign Questionnaire.
                            </h6>
                            Use this option to assign a questionnaire to the client to be completed.
                            <div className="text-end mt-3">
                                <button className="btn btn-secondary btn-sm" onClick={() => h.replace('/practitioner/cases/single_case', { id: state._id, currentPage: 'questionnaire' })}>Asssign Questionnaire</button>
                            </div>
                        </div>
                    </div><div className="col-11 col-md-8 col-lg-6">
                        <div class="alert alert-secondary" role="alert" style={{ background: 'rgba(209,210,211,0.8)' }}>
                            <h6>
                                <ion-icon name="checkmark-done-outline"></ion-icon> View all cases
                            </h6>
                            Use this option to view all your case records
                            <div className="text-end mt-3">
                                <button className="btn btn-secondary btn-sm" onClick={() => h.replace('/practitioner/cases')}>View Cases</button>
                            </div>
                        </div>
                    </div><div className="col-11 col-md-8 col-lg-6">
                        <div class="alert alert-secondary" role="alert" style={{ background: 'rgba(209,210,211,0.8)' }}>
                            <h6>
                                <ion-icon name="checkmark-done-outline"></ion-icon> Add another case
                            </h6>
                            Use this option if you wish to add a new case.
                            <div className="text-end mt-3">
                                <button className="btn btn-secondary btn-sm" onClick={() => setshowNewCaseModel(true)}> Add another case</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CaseCreationSuccessPage
