import React from 'react'
import _ from 'lodash';
function PracHomePage() {
    return (
        <div style={{ minHeight: '100vh' }}>
            <div className="contaisner">
                <div className="alert alert-danger" color="danger">
                    <div>
                        <i className="fas fa-exclamation-circle me-2"></i>
                        Preview period expires Oct 01, 2021. Unlock your full potential by upgrading to a paid plan today and booking your 1-on-1 onboarding session.
                    </div>
                    <button className="btn btn-danger" >Schedule</button>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="card">
                            <div className='elegant-text rounded p-2'>
                                <div className="fw-bold">Quickstart Guide</div>
                                <img src="https://picsum.photos/536/354" alt="" className="w-100 my-2" style={{ height: 100 }} />
                                <div>
                                    Review the step-by-step quickstart guide to get started. The steps are easy to follow and you'll be up and running in no time.
                                </div>
                                <button className="btn btn-sm mt-3 btn-primary br">Learning Resources</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* second row */}
                <div className="row my-3 g-5">
                    <div className="col-8">
                        <div className="card">
                            <div className='elegant-text rounded p-0 pb-4'>
                                <div className="fw-bold p-2">
                                    Latest products, features and announcements
                                </div>
                                <div className="my-2 overflow-hidden">
                                    {_.times(3).map(d => (<div className="row justify-content-between bg-light px-2 my-1">
                                        <div className="col-9">
                                            Check out our E-Signature software to get important documents signed without the hassle.
                                            No need to Print and Scan the Use of a Representative Form or any PDFs, integrates with the CRM.
                                        </div>
                                        <div className="col-3 text-end ">
                                            <button className="btn btn-sm btn-primary br mt-4">
                                                Learn More
                                            </button>
                                        </div>
                                    </div>))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card">
                            <div className='elegant-text rounded p-0 pb-5'>
                                <div className="fw-bold p-2">
                                    Upcoming Tasks
                                </div>
                                <div className="my-2 bg-light p-2">
                                    <div className="row fw-bold">
                                        <div className="col-6">Task</div>
                                        <div className="col-6">Duration</div>
                                    </div>
                                    <div className="row">
                                        {_.times(3).map(d => (
                                            <>
                                                <div className="col-6 my-3">
                                                    No Task
                                                </div>
                                                <div className="col-6 text-center"> -- </div>
                                            </>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* third row */}
                <div className="row my-3 g-5">
                    <div className="col-8">
                        <div className="card">
                            <div className='elegant-text rounded p-0 pb-4'>
                                <div className="fw-bold p-2">
                                    Continuing Professional Development
                                </div>
                                <div className="my-2 overflow-hidden">
                                    {_.times(3).map(d => (<div className="row justify-content-between bg-light px-2 my-1">
                                        <div className="col-9">
                                            Check out our E-Signature software to get important documents signed without the hassle.

                                        </div>
                                        <div className="col-3 text-end ">
                                            <button className="btn btn-sm btn-primary br mt-4">
                                                Learn More
                                            </button>
                                        </div>
                                    </div>))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="card">
                            <div className='elegant-text rounded p-0 pb-3'>
                                <div className="fw-bold p-2">
                                    Latest Express Entry Draws
                                </div>
                                <div className="my-2 bg-light p-2">
                                    <div className="row fw-bold">
                                        <div className="col-6">Date</div>
                                        <div className="col-6">Lowest CRS Score</div>
                                    </div>
                                    <div className="row">
                                        {_.times(3).map(d => (
                                            <>
                                                <div className="col-6 my-3">
                                                    {new Date().toDateString()}
                                                </div>
                                                <div className="col-6 text-center"> -- </div>
                                            </>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* fourth row */}

            </div>
        </div>
    )
}

export default PracHomePage
