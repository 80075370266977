import React, { useState, useEffect } from 'react'


function VfInputLabel({ type,
    inputHandler,
    placeholder,
    error,
    value,
    isDisable,
    icon,
    showSuccess,
    id, required, label, showLabel, optional }) {
    const [showPassword, setshowPassword] = useState(false)
    const style = {
        inputContainer: {
            // paddingLeft: 15,
            // padding: 2,
            overflow: "hidden",
            width: "100%",
            outline: "none",
            border: error ? "2px solid #f00" : "2.5px solid var(--primary)",
            borderRadius: "10px",
            height: 50,
            transition: "all .3s ease-in-out",
            background:"#fff"
        },
        input: {
            outline: "none",
            border: "none",
            width: '50%',
            height: '100%'
        },
        icon: {
            width: '10%',
            color: 'var(--primary)',
            opacity: 0.7
        },
        domain: {
            fontSize: 20,
            background: "#d1d2d4",
            height: '100%',
            lineHeight: "20px", padding: 10
        },
        label: {
            color: 'var(--primary)',
            fontWeight: 700,
            opacity: 0.7
        }
    };
    return (
        <div className="my-2">
            {
                showLabel &&
                <div style={style.label} className="my-1 ms-2">
                    <div className="d-inline-block pg-text__bold ">{label}</div>
                    {required && <span className="textc-primary fst-italic"> (Required)</span>}
                    {optional && !isDisable && <span className="textc-primary fst-italic"> (Optional)</span>}
                </div>
            }
            <div >
                <div
                    className="d-flex flex-row"
                    style={style.inputContainer}
                >
                    {icon && <div className="d-inline-block text-center fs-4 my-auto" style={style.icon}>
                        {icon}
                    </div>}
                    <input
                        placeholder={placeholder}
                        type={type}
                        style={style.input}
                        onInput={inputHandler}
                        value={value}
                        disabled={isDisable}
                        id={id}
                    />
                    <div className="my-auto ms-auto fw-bold" style={style.domain}>
                        .visafy.ca
                    </div>
                </div>
                {showSuccess
                    ? <div
                        className="text-success mx-2 my-1 transition-all"
                    >
                        <i class="fas fa-check-double me-2" ></i> Subdomain available
                    </div>
                    : <div
                        className="text-danger mx-2 my-1 transition-all"
                        style={error ? { opacity: 1 } : { opacity: 0 }}
                    >
                        <i class="fas fa-exclamation-triangle me-2" ></i> {error}
                    </div>}
            </div>
        </div>
    )
}

export default VfInputLabel
