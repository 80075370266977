import { createAction } from "redux-api-middleware";
import axios from "axios";
const BASE_URL = process.env.REACT_APP_API;

export const CASE_CREATE_REQUEST = "CASE_CREATE_REQUEST";
export const CASE_CREATE_SUCCESS = "CASE_CREATE_SUCCESS";
export const CASE_CREATE_FAILURE = "CASE_CREATE_FAILURE";

export const getApplicant = (caseID, token) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/applicant/${caseID}`,
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
        types: [
            CASE_CREATE_REQUEST,
            CASE_CREATE_SUCCESS,
            CASE_CREATE_FAILURE,
        ],
    });
};

export const createApplicant = (data, token) => {
    return createAction({
        endpoint: BASE_URL + `/api/v1/applicant`,
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token },
        types: [
            CASE_CREATE_REQUEST,
            CASE_CREATE_SUCCESS,
            CASE_CREATE_FAILURE,
        ],
    });
};