import * as auth from "../actions/auth.js";
import jwtDecode from "jwt-decode";
import toastr from 'react-hot-toast'
// import Cookies from 'universal-cookie';
// const cookies = new Cookies();

const initialState = {
  isAuth: false,
  loading: false,
  email: "",
  fName: "",
  lName: "",
  fullName: "",
  mobileno: "",
  company: "",
  token: "",
  userType: "",
  currentSD: "",
  currentUserID: "",

};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case auth.AUTH_REQUEST: {
      return { ...state, loading: true, isAuth: false };
    }
    case auth.AUTH_SUCCESS: {
      // const decodedData = jwtDecode(action.payload.token);
      console.log(action.payload, "pld");
      const { company, email, fName, lName, userType, token, mobileno, subdomain, _id } = action.payload;
      // if (subdomain.name !== state.currentSD) {
      //   toastr.error("Account doesnt exist!")
      //   return { ...state, loading: false }
      // }
      return {
        ...state,
        isAuth: true,
        email,
        fName,
        lName,
        fullName: fName + " " + lName,
        mobileno,
        loading: false,
        company,
        token,
        userType,
        currentUserID: _id
      };
    }
    case auth.AUTH_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case auth.LOGOUT: {
      return {
        ...state,
        isAuth: false,
        email: "",
        fName: "",
        lName: "",
        fullName: '',
        mobileno: "",
        loading: false,
        company: "",
        token: "",
        userType: "",
        currentUserID: "",
      };
    }

    case auth.CHANGE_AUTH: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case auth.AUTH_FAILURE: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
