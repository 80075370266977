import moment from 'moment'
import React from 'react'

function CCaseCard({ data }) {
    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className="card my-2 shadow br p-2" style={{}}>
                <div className="fw-bold">
                    {data.name}
                </div>
                <div className="badge bg-primary">
                    {data.client}
                </div>
                <div>
                    {data.caseType}
                </div>
                <div>
                    <b>Created</b> {moment(data?.createdAt).fromNow()}
                </div>
                <div>
                    {data.updatedAt}
                </div>
                <div>
                    {data.caseStatus}
                </div>
                <div className="text-center">
                    <button className="btn btn-primary">View Case</button>
                </div>
            </div>
        </div>
    )
}

export default CCaseCard
