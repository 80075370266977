import React from 'react'
import SampleDashboardPage from '../../component/Practitioner/Dashboard/SampleDashboardPage'

function PracDashboard() {
    return (
        <div>
            <SampleDashboardPage />
        </div>
    )
}

export default PracDashboard
