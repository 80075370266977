import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import VfInput from '../../utils/VfInput';
import VfSelect from '../../utils/VfSelect';
import toastr from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { createCase } from '../../../store/actions/case';
import VfCheckBox from '../../utils/VfCheckBox';
import { useHistory } from 'react-router';
import { caseTypes } from '../../../assets/input_data.json'

function CreateNewCaseModal({ show, handleClose, fromCAP = false, aemail, afname, alname, aid }) {
    const h = useHistory();
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token)

    const [email, setemail] = useState("");
    const [fName, setfName] = useState("");
    const [lName, setlName] = useState("");
    const [id, setid] = useState("");
    const [clientPortAccess, setclientPortAccess] = useState(fromCAP)
    const [caseType, setcaseType] = useState("");
    const [loading, setloading] = useState(false);
    useEffect(() => {
        if (fromCAP) {
            setemail(aemail); setfName(afname); setlName(alname); setid(aid);
        }
    }, [])

    const createCaseFn = async () => {
        if (caseType.length !== 0) {
            const data = {
                existingClient: fromCAP,
                cid: id,
                caseType,
                email,
                fName, lName,
            }
            const response = await dispatch(createCase(data, token))
            if (!response.error) {
                console.log('response ', response);
                h.push('/practitioner/cases/success', response.payload.data);
                toastr.success('Case created successfully!');
                handleClose();
            }
        } else {
            toastr.error('Select case type');

        }
    }

    return (
        <Modal
            centered
            contentClassName="br"
            // backdrop="static"
            visible={show} onCancel={handleClose}
            footer={[
                <button className="btn btn-secondary mx-1 px-5" onClick={handleClose}>
                    Close
                </button>,
                <button className="btn btn-primary mx-1 px-5" onClick={createCaseFn}>
                    Create
                </button>
            ]}
        >
            {/* <Modal> */}
            <div className="container-xxl">
                <h3>
                    Add Case Information
                </h3>
                <div className="row">
                    <div className="col-6">
                        <VfSelect
                            showLabel
                            label="Case Type"
                            placeholder="Select Case Type"
                            showLabel
                            name="case_type"
                            value={caseType}
                            required
                            onChange={(e) => setcaseType(e)}
                            selectOptions={caseTypes}
                        />
                        <VfInput
                            showLabel
                            label="Email"
                            value={email}
                            inputHandler={(e) => setemail(e.target.value)}
                            isDisable={fromCAP}
                        />
                        <VfInput
                            showLabel
                            label="First Name"
                            value={fName}
                            inputHandler={(e) => setfName(e.target.value)}
                        />
                        <VfInput
                            showLabel
                            label="Last Name"
                            value={lName}
                            inputHandler={(e) => setlName(e.target.value)}
                        />
                        <VfCheckBox
                            // isDisable={fromCAP}
                            checked={clientPortAccess}
                            onCheck={() => setclientPortAccess(!clientPortAccess)}
                        >
                            Enable Access to Client Portal
                        </VfCheckBox>

                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default CreateNewCaseModal
