import React from 'react'
import { useSelector } from 'react-redux'

function PracAccountPage() {
    const email = useSelector(state => state.auth.email)
    const company = useSelector(state => state.auth.company)
    const name = useSelector(state => state.auth.fullName)
    const userType = useSelector(state => state.auth.userType)
    const mobileno = useSelector(state => state.auth.mobileno)
    return (
        <div>
            <div style={{ minHeight: '100vh' }}>
                Account Page
                <div className="container-xxl">
                    <div className="row">
                        <div className="col-12">Email : {email}</div>
                        <div className="col-12">Company : {company}</div>
                        <div className="col-12">Full Name : {name}</div>
                        <div className="col-12">Role : {userType}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PracAccountPage
