import React from 'react'

function CaseTasksPage() {
    return (
        <div>
            Case Tasks Page
        </div>
    )
}

export default CaseTasksPage
