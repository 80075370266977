import React from 'react'
import L_CasePage from '../../component/Loading/Case/L_CasePage';
import CaseTable from '../../component/Practitioner/Case/CaseTable';


function CasesPage() {

    return (
        <div>
            <div className="my-1">
                <CaseTable />
            </div>
        </div>
    )
}

export default CasesPage
